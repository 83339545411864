<template>
  <div class="file">
    <template v-if="isImage">
      <div class="file__img" @click="onClick">
        <img v-if="!isImageError" :src="url" @error="onImgError" />
      </div>
    </template>
    <template v-else>
      <div class="file__info">
        <div class="file__info-wrapper">
          <div class="file__icon">
            <fluent-icon icon="document" size="20" />
          </div>
          <div class="file__meta">
            <div class="file__meta-title">
              {{ decodeURI(fileName) }}
            </div>
            <a
              class="file__meta-link"
              rel="noreferrer noopener nofollow"
              target="_blank"
              :href="url"
            >
              {{ $t('CONVERSATION.DOWNLOAD') }}
            </a>
          </div>
        </div>
      </div>
    </template>
    <email-attachment-preview
      v-if="show"
      :key="attachment.message_id"
      :show.sync="show"
      :attachment="attachment"
      :all-attachments="getCurrentAttachments"
      @error="onImgError"
      @close="onClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailAttachmentPreview from '../components/EmailAttachmentPreview.vue';
import { hasPressedCommand } from 'shared/helpers/KeyboardHelpers';

export default {
  components: {
    EmailAttachmentPreview,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isImageError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChatAttachments: 'getSelectedChatAttachments',
    }),
    fileName() {
      if (this.url) {
        const filename = this.url.substring(this.url.lastIndexOf('/') + 1);
        return filename || this.$t('CONVERSATION.UNKNOWN_FILE_TYPE');
      }
      return this.$t('CONVERSATION.UNKNOWN_FILE_TYPE');
    },
    getCurrentAttachments() {
      return this.currentChatAttachments;
    },
    isImage() {
      if (!this.url) return false;
      const pathnameParts = this.url.split('/');
      const filename = decodeURIComponent(
        pathnameParts[pathnameParts.length - 1]
      );
      const fileExtension = filename.split('.').pop().toLowerCase();
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

      return imageExtensions.includes(fileExtension);
    },
  },
  methods: {
    openLink() {
      const win = window.open(this.url, '_blank', 'noopener');
      if (win) win.focus();
    },
    onClose() {
      this.show = false;
    },
    onImgError() {
      this.isImageError = true;
      this.$emit('error');
    },
    onClick(e) {
      if (hasPressedCommand(e)) {
        window.open(this.attachment.data_url, '_blank');
        return;
      }
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@tailwind components;
@layer components {
  .custom-gradient-incoming {
    @apply bg-gradient-to-b from-transparent to-bubble;
  }

  .custom-gradient-incoming-dark {
    @apply bg-gradient-to-b from-transparent to-bubble-dark;
  }
}

.file {
  @apply flex w-full pr-1.5 mb-2;

  &:has(.file__icon) {
    @apply h-full;
  }

  &__img {
    @apply cursor-pointer w-full max-h-[130px] overflow-hidden relative rounded-[4px];

    &:before {
      @apply custom-gradient-incoming dark:custom-gradient-incoming-dark bottom-0 h-[20%] content-[''] left-0 absolute w-full opacity-80 pointer-events-none;
    }
  }

  &__info {
    @apply flex relative overflow-hidden w-full;
  }

  &__info-wrapper {
    @apply flex;
  }

  &__icon {
    @apply pt-1 text-primary dark:text-primary-dark mb-0.5 mr-1;
  }

  &__meta {
    &-title {
      @apply text-secondary dark:text-secondary-dark font-medium m-0 pr-1 text-xs line-clamp-2 max-w-[120px];
      word-break: break-word;
    }
    &-link {
      @apply text-xs hover:underline font-medium;
    }
  }
}
</style>
