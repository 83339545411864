<template>
  <li
    v-show="isMenuItemVisible"
    class="mt-2"
    :class="{ 'mb-6': menuItem.key === 'conversation_unattended' }"
  >
    <!-- if has Submenu -->
    <div
      v-if="hasSubMenu"
      ref="dropdownParentEl"
      class="subhead"
      :class="{
        'justify-center': !isSidebarExpanded,
        'cursor-pointer': isSidebarExpanded,
        'subhead-active': isSubheadActive && !isSidebarExpanded,
        'hover:bg-neutral-medium hover:text-secondary dark:hover:bg-[#222527]':
          !isSubheadActive && !isSidebarExpanded,
      }"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @click="toggleAccordion"
    >
      <!-- subhead icon -->
      <div
        v-if="isInHomepage"
        class="subhead__head"
        :class="isSidebarExpanded ? 'mr-2' : 'justify-center mr-0'"
      >
        <Icon
          :size="menuItem.iconSize"
          :view-box="menuItem.viewBox"
          :icon="menuItem.icon"
          type="outline"
          :force-light-color="menuItem.forceLightColor"
          :icons="iconLib"
          is-in-sidebar
        />
      </div>

      <!-- subhead label -->
      <div v-if="isSidebarExpanded" class="subhead__body">
        {{ $t(`SIDEBAR.${menuItem.label}`) }}
      </div>

      <!-- subhead accordion icon -->
      <div v-if="isSidebarExpanded" class="subhead__foot">
        <fluent-icon
          v-if="isAccordionOpen"
          size="16"
          icon="chevron-up"
          type="outline"
        />
        <fluent-icon v-else size="16" icon="chevron-down" type="outline" />
      </div>

      <!-- submenu dropdown when sidebar is collapsed -->
      <div
        v-if="!isSidebarExpanded && isShowChildMenu && hasSubMenu"
        :style="childStyle"
        class="dropdown-menu"
        @mouseenter="childHovered = true"
        @mouseleave="childHovered = false"
      >
        <!-- hover area helper -->
        <div class="dropdown-menu__helper" />

        <!-- dropdown submenu -->
        <div class="dropdown-menu__content">
          <div class="dropdown-menu__head">
            {{ $t(`SIDEBAR.${menuItem.label}`) }}
          </div>
          <div class="dropdown-menu__wrapper">
            <div
              v-if="menuItem.children && menuItem.children.length === 0"
              class="dropdown-menu__empty"
            >
              {{ $t(`SIDEBAR.${menuItem.label}`) }} is empty.
            </div>
            <ul v-else class="dropdown-menu__body">
              <new-secondary-child-nav-item
                v-for="child in menuItem.children"
                :key="child.id"
                :to="child.toState"
                :label="child.label"
                :label-color="child.color"
                :should-truncate="child.truncateLabel"
                :icon="computedInboxClass(child)"
                :warning-icon="computedInboxErrorClass(child)"
                :show-child-count="showChildCount(child.count)"
                :child-item-count="child.count"
                is-inside-dropdown
                @click="findParentId"
              />
            </ul>
          </div>
          <div v-if="showItem(menuItem)" class="dropdown-menu__foot">
            <router-link v-slot="{ navigate }" :to="menuItem.toState" custom>
              <div
                class="dropdown-menu__action"
                :data-testid="menuItem.dataTestid"
                @click="e => newLinkClick(e, navigate)"
              >
                <fluent-icon icon="add" class="dropdown-menu__action-icon" />
                <div class="dropdown-menu__action-text">
                  {{ $t(`SIDEBAR.${menuItem.newLinkTag}`) }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Doesn't have submenu -->
    <router-link
      v-else
      v-tooltip.right="tooltipOptions"
      class="menu-item"
      :class="[
        computedClass,
        {
          'menu-item-disabled': isDisabled,
          'justify-center': !isSidebarExpanded,
        },
      ]"
      :to="!isDisabled ? menuItem && menuItem.toState : ''"
    >
      <div v-if="isInHomepage" :class="{ 'mr-2': isSidebarExpanded }">
        <Icon
          :class="{
            'ml-auto mr-auto': !isSidebarExpanded,
          }"
          size="21"
          :view-box="menuItem.viewBox"
          :icon="menuItem.icon"
          type="outline"
          :force-light-color="menuItem.forceLightColor"
          :icons="iconLib"
          is-in-sidebar
        />
      </div>
      <template v-if="isSidebarExpanded">
        <div class="menu-item__text">
          {{ $t(`SIDEBAR.${menuItem.label}`) }}
        </div>
        <div
          v-if="showChildCount(menuItem.count)"
          class="rounded-sm text-xxs mx-1 py-0 px-1"
          :class="{
            'text-accent dark:text-accent-dark': isCountZero && !isActiveView,
            'text-accent dark:text-accent-dark': !isCountZero && !isActiveView,
            'bg-accent-low dark:bg-accent-dark-low text-accent dark:text-accent-dark':
              isActiveView,
            'bg-accent-low dark:bg-accent-dark-low text-accent dark:text-accent-dark':
              !isActiveView,
          }"
        >
          {{ `${menuItem.count}` }}
        </div>
      </template>

      <!-- show beta version badge -->
      <span
        v-if="menuItem.beta"
        data-view-component="true"
        label="Beta"
        class="px-1 mx-1 inline-block font-medium leading-4 border border-green-400 text-green-500 rounded-lg text-xxs"
      >
        {{ $t('SIDEBAR.BETA') }}
      </span>
    </router-link>

    <!-- Submenu accordion -->
    <template v-if="isAccordionOpen">
      <!-- submenu -->
      <ul v-if="hasSubMenu && isSidebarExpanded" class="submenu">
        <new-secondary-child-nav-item
          v-for="child in menuItem.children"
          :key="child.id"
          :to="child.toState"
          :label="child.label"
          :label-color="child.color"
          :should-truncate="child.truncateLabel"
          :icon="computedInboxClass(child)"
          :warning-icon="computedInboxErrorClass(child)"
          :show-child-count="showChildCount(child.count)"
          :child-item-count="child.count"
          @click="findParentId"
        />
        <router-link
          v-if="showItem(menuItem)"
          v-slot="{ navigate }"
          :to="menuItem.toState"
          custom
        >
          <div
            class="submenu__action"
            :data-testid="menuItem.dataTestid"
            @click="e => newLinkClick(e, navigate)"
          >
            <fluent-icon icon="add" class="submenu__action-icon" />
            <div class="submenu__action-text">
              {{ $t(`SIDEBAR.${menuItem.newLinkTag}`) }}
            </div>
          </div>
        </router-link>
      </ul>
    </template>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../../mixins/isAdmin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';
import NewSecondaryChildNavItem from './NewSecondaryChildNavItem.vue';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';

export default {
  components: {
    NewSecondaryChildNavItem,
    Icon,
  },
  mixins: [adminMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
    viewBox: {
      type: String,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
    activeMenuKey: {
      type: String,
      default: '',
    },
    isInHomepage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowChildMenu: false,
      childHovered: false,
      childStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      isAccordionOpen: true,
    };
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
    }),
    isCountZero() {
      return this.menuItem.count === 0;
    },
    isActiveView() {
      return this.computedClass.includes('active-view');
    },
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }
      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }

      // check if menu item has posthog
      // app/javascript/dashboard/components/layout/config/sidebarItems/settings.js
      if (this.menuItem.posthogFeatureFlag) {
        return this.$featureFlags[this.menuItem.posthogFeatureFlag];
      }
      return true;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },
    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isCurrentRoute
      ) {
        return 'menu-item-active';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
        }
        return 'hover:text-secondary dark:hover:text-secondary';
      }

      return 'hover:text-secondary dark:hover:text-secondary';
    },
    currentRoute() {
      return this.$route.path;
    },
    isSubheadActive() {
      if (this.currentRoute.startsWith(this.menuItem.toState)) {
        return true;
      }
      return (
        this.menuItem.children &&
        this.menuItem.children.some(child =>
          this.currentRoute.startsWith(child.toState)
        )
      );
    },
    tooltipOptions() {
      if (this.isSidebarExpanded) {
        return { content: '', classes: '' };
      }
      return {
        content: this.$t(`SIDEBAR.${this.menuItem.label}`),
        classes: 'new-sidebar-tooltip',
      };
    },
    iconLib() {
      return icons;
    },
  },
  watch: {
    isSidebarExpanded(val) {
      if (val) {
        if (this.activeMenuKey === this.menuItem.key) {
          this.isAccordionOpen = true;
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateChildPosition);
      this.updateChildPosition();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChildPosition);
  },
  methods: {
    computedInboxClass(child) {
      const { type, phoneNumber, is_meta_comments_inbox } = child;
      if (!type) return '';
      const classByType = getInboxClassByType(
        type,
        phoneNumber,
        is_meta_comments_inbox
      );
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      }

      if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        }

        if (this.menuItem.modalName === 'AddFolder') {
          e.preventDefault();
          this.$emit('add-folder');
        }

        if (this.menuItem.modalName === 'AddCategory') {
          e.preventDefault();
          this.$emit('add-category');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && !!item.newLink;
    },
    showChildCount(count) {
      return Number.isInteger(count);
    },
    updateChildPosition() {
      if (this.$refs.dropdownParentEl) {
        const parentRect = this.$refs.dropdownParentEl.getBoundingClientRect();
        this.childStyle.top = `${parentRect.bottom - 33}px`;
        this.childStyle.left = `${parentRect.left + 64}px`;
      }
    },
    handleMouseEnter() {
      this.isShowChildMenu = true;
      this.$nextTick(this.updateChildPosition);
    },
    handleMouseLeave() {
      setTimeout(() => {
        if (!this.childHovered) {
          this.isShowChildMenu = false;
        }
      }, 50);
    },
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    findParentId() {
      this.isShowChildMenu = false;
      this.childHovered = false;
      this.$emit('click', this.menuItem.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  p-2
  m-0
  h-[33px]
  text-sm
  text-secondary
  dark:text-secondary
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  border
  border-transparent;

  &__icon {
    @apply w-[18px] h-[19px] relative;
  }
  &__text {
    @apply leading-none;
  }
}

// menu item active state
.menu-item-active {
  @apply text-primary
  dark:text-primary-dark
  bg-white
  dark:bg-[#222527]
  border-primary-dark
  dark:border-[#474D52]
  shadow-active-menu
  dark:shadow-active-menu-dark;

  &:hover {
    @apply text-primary dark:text-primary-dark cursor-default bg-white dark:bg-[#222527];
  }
}

.subhead {
  @apply flex
  items-center
  rounded-[4px]
  h-[33px]
  text-secondary
  dark:text-secondary
  border
  border-transparent
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  select-none
  p-2;

  &__head {
    @apply w-[21px] h-[21px] flex items-center;
  }
  &__body {
    @apply text-sm text-secondary dark:text-secondary font-medium my-2 leading-none;
  }
  &__foot {
    @apply w-[16px] h-[16px] flex items-center justify-center mx-2;
  }
}

// subhead active state
.subhead-active {
  @apply text-secondary
  dark:text-secondary
  bg-white
  dark:bg-[#222527]
  border-primary-dark
  dark:border-[#474D52]
  shadow-active-menu
  dark:shadow-active-menu-dark
  hover:bg-white;
}

.menu-item-disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    background: transparent !important;
  }
}

.submenu {
  @apply list-none ml-[17px] pl-[17px] border-l border-neutral-high dark:border-neutral-dark-high mb-2;

  &__action {
    @apply flex items-center leading-4 text-sm w-full cursor-pointer px-2 py-2 text-accent dark:text-accent-dark rounded-[4px] mt-2 mb-2;

    &:hover {
      @apply bg-accent-low dark:bg-accent-dark-low;
    }
  }
  &__action-icon {
    @apply w-[16px] h-[16px] relative mr-1.5 rtl:mr-0 rtl:ml-1.5;
  }
  &__action-text {
    @apply leading-none font-medium;
  }
}

.dropdown-menu {
  @apply z-10 w-[245px];

  // hover area helper
  &__helper {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -22px;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    @apply overflow-hidden rounded-lg shadow-dropdown relative bg-white dark:bg-[#222526] border border-neutral-medium dark:border-neutral-dark-medium;
  }

  &__wrapper {
    @apply w-full overflow-y-auto px-2 pb-2 max-h-[180px];
  }

  &__head {
    @apply flex items-center h-[33px] text-secondary dark:text-secondary-dark sticky top-0 left-0 w-full bg-white dark:bg-[#222526] z-10 px-4 text-[13px];
  }

  &__body {
    @apply list-none mb-0 ml-0;
  }

  &__foot {
    @apply flex
    items-center
    sticky
    bottom-0
    left-0
    w-full
    bg-white
    dark:bg-[#222526]
    z-10
    border-t
    border-neutral-medium
    dark:border-neutral-dark-medium;
  }

  &__action {
    @apply flex items-center leading-4 text-sm w-full cursor-pointer px-3 py-2 text-accent dark:text-accent-dark m-1 rounded-[4px];

    &:hover {
      @apply bg-accent-low dark:bg-accent-dark-low;
    }
  }
  &__action-icon {
    @apply w-[16px] h-[16px] relative mr-1.5 rtl:mr-0 rtl:ml-1.5;
  }
  &__action-text {
    @apply leading-none;
  }

  &__empty {
    @apply h-[75px] flex items-center justify-center text-sm text-secondary dark:text-secondary-dark;
  }
}
</style>
