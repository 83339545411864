<template>
  <div class="resolve-actions relative flex items-center justify-end">
    <div class="button-group">
      <woot-button
        v-if="isOpen"
        class-names="resolve"
        color-scheme="success"
        icon="checkmark"
        emoji="✅"
        size="small"
        :is-loading="isLoading"
        @click="onCmdResolveConversation"
      >
        {{ $t('CONVERSATION.HEADER.RESOLVE_ACTION') }}
      </woot-button>
      <woot-button
        v-else-if="isResolved"
        class-names="resolve"
        color-scheme="warning"
        icon="arrow-redo"
        emoji="👀"
        size="small"
        :is-loading="isLoading"
        @click="onCmdOpenConversation"
      >
        {{ $t('CONVERSATION.HEADER.REOPEN_ACTION') }}
      </woot-button>
      <woot-button
        v-else-if="showOpenButton"
        class-names="resolve"
        color-scheme="primary"
        icon="person"
        size="small"
        :is-loading="isLoading"
        @click="onCmdOpenConversation"
      >
        {{ $t('CONVERSATION.HEADER.OPEN_ACTION') }}
      </woot-button>
      <woot-button
        v-if="showAdditionalActions"
        ref="arrowDownButton"
        :color-scheme="buttonClass"
        :disabled="isLoading"
        icon="chevron-down"
        emoji="🔽"
        size="small"
        class="border-l-0"
        @click="openDropdown"
      />
    </div>
    <div
      v-if="showActionsDropdown"
      v-on-clickaway="closeDropdown"
      class="dropdown-pane dropdown-pane--open"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="isCsatEnabled && !isResolved">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="checkmark"
            @click="() => toggleStatus(STATUS_TYPE.RESOLVED, undefined, true)"
          >
            {{ $t('CONVERSATION.RESOLVE_DROPDOWN.RESOLVE_NO_CSAT') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="!isPending">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="snooze"
            @click="() => openSnoozeModal()"
          >
            {{ $t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE_UNTIL') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="!isPending">
          <woot-button
            v-if="!currentChat.muted"
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="speaker-mute"
            @click="mute"
          >
            {{ $t('CONTACT_PANEL.MUTE_CONTACT') }}
          </woot-button>
          <woot-button
            v-else
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="speaker-unmute"
            @click="unmute"
          >
            {{ $t('CONTACT_PANEL.UNMUTE_CONTACT') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="!isPending">
          <woot-button
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="share"
            @click="toggleEmailActionsModal"
          >
            {{ $t('CONTACT_PANEL.SEND_TRANSCRIPT') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
    <woot-modal
      :show.sync="showCustomSnoozeModal"
      :on-close="hideCustomSnoozeModal"
    >
      <custom-snooze-modal
        @close="hideCustomSnoozeModal"
        @choose-time="chooseSnoozeTime"
      />
    </woot-modal>
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
  </div>
</template>

<script>
import { getUnixTime } from 'date-fns';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import CustomSnoozeModal from 'dashboard/components/CustomSnoozeModal.vue';
// import {
//   hasPressedAltAndEKey,
//   hasPressedCommandPlusAltAndEKey,
//   hasPressedAltAndMKey,
// } from 'shared/helpers/KeyboardHelpers';
import { findSnoozeTime } from 'dashboard/helper/snoozeHelpers';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';

import wootConstants from 'dashboard/constants/globals';
import {
  CMD_REOPEN_CONVERSATION,
  CMD_RESOLVE_CONVERSATION,
  CMD_SNOOZE_CONVERSATION,
} from '../../routes/dashboard/commands/commandBarBusEvents';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../routes/dashboard/commands/commandBarBusEvents';
import EmailTranscriptModal from 'dashboard/components/widgets/conversation/EmailTranscriptModal.vue';

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
    CustomSnoozeModal,
    EmailTranscriptModal,
  },
  mixins: [clickaway, alertMixin],
  inject: ['uiCommandBus'],
  props: { conversationId: { type: [String, Number], required: true } },
  data() {
    return {
      isLoading: false,
      showActionsDropdown: false,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
      showCustomSnoozeModal: false,
      showEmailActionsModal: false,
    };
  },
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat' }),
    isOpen() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.OPEN;
    },
    isPending() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.PENDING;
    },
    isResolved() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.RESOLVED;
    },
    isSnoozed() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    isCsatEnabled() {
      return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id)
        ?.csat_survey_enabled;
    },
    buttonClass() {
      if (this.isPending) return 'primary';
      if (this.isOpen) return 'success';
      if (this.isResolved) return 'warning';
      return '';
    },
    showAdditionalActions() {
      return !this.isPending && !this.isSnoozed;
    },
  },
  mounted() {
    bus.$on(CMD_SNOOZE_CONVERSATION, this.onCmdSnoozeConversation);
    bus.$on(CMD_REOPEN_CONVERSATION, this.onCmdOpenConversation);
    bus.$on(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);

    // keyboard shortcut for mute and transcript
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);

    this.uiCommandBus.$on('command', this.handleUiCommand);
  },
  destroyed() {
    this.uiCommandBus.$off('command', this.handleUiCommand);

    bus.$off(CMD_SNOOZE_CONVERSATION, this.onCmdSnoozeConversation);
    bus.$off(CMD_REOPEN_CONVERSATION, this.onCmdOpenConversation);
    bus.$off(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);

    // keyboard shortcut for mute and transcript
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    onCmdSnoozeConversation(snoozeType) {
      if (snoozeType === wootConstants.SNOOZE_OPTIONS.UNTIL_CUSTOM_TIME) {
        this.showCustomSnoozeModal = true;
      } else {
        this.toggleStatus(
          this.STATUS_TYPE.SNOOZED,
          findSnoozeTime(snoozeType) || null
        );
      }
    },
    chooseSnoozeTime(customSnoozeTime) {
      this.showCustomSnoozeModal = false;
      if (customSnoozeTime) {
        this.toggleStatus(
          this.STATUS_TYPE.SNOOZED,
          getUnixTime(customSnoozeTime)
        );
      }
    },
    hideCustomSnoozeModal() {
      this.showCustomSnoozeModal = false;
    },
    onCmdOpenConversation() {
      this.toggleStatus(this.STATUS_TYPE.OPEN);
    },
    onCmdResolveConversation() {
      if (this.isResolved) {
        this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS_FAILED'));
        return;
      }

      this.resolveCurrentAndMoveToNext();
    },
    showOpenButton() {
      return this.isResolved || this.isSnoozed;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    openDropdown() {
      this.showActionsDropdown = true;
    },
    toggleStatus(status, snoozedUntil, noCsat) {
      this.closeDropdown();
      this.isLoading = true;
      this.$store
        .dispatch('toggleStatus', {
          conversationId: this.currentChat.id,
          status,
          snoozedUntil,
          noCsat,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
          this.isLoading = false;
        });
    },
    openSnoozeModal() {
      const ninja = document.querySelector('ninja-keys');
      ninja.open({ parent: 'snooze_conversation' });
    },
    async resolveCurrentAndMoveToNext(
      // status = wootConstants.STATUS_TYPE.RESOLVED,
      snoozedUntil = null
    ) {
      const allConversations = document.querySelectorAll(
        '.conversations-list .conversation'
      );
      const activeConversation = document.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;

      try {
        await this.toggleStatus(
          wootConstants.STATUS_TYPE.RESOLVED,
          snoozedUntil
        );
      } catch (error) {
        // error
      }

      if (activeConversationIndex < lastConversationIndex) {
        allConversations[activeConversationIndex + 1].click();
      } else if (allConversations.length > 1) {
        allConversations[0].click();
        document.querySelector('.conversations-list').scrollTop = 0;
      }
    },
    handleUiCommand(command) {
      if (command === 'resolve-conversation') this.onCmdResolveConversation();
      else if (command === 'snooze-conversation') this.openSnoozeModal();
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
      this.showActionsDropdown = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.resolve-actions {
  ::v-deep {
    .button.resolve,
    .button.button--only-icon {
      @apply h-[32px];
    }
  }
}
.dropdown-pane {
  @apply left-auto top-[2rem] mt-0.5 right-0 max-w-[12.5rem] min-w-[9.75rem];

  .dropdown-menu__item {
    @apply mb-0;
  }
}
</style>
