<template>
  <div
    class="bg-neutral-subtle flex justify-between dark:bg-neutral-dark-light relative"
  >
    <div class="reply-top">
      <div
        class="button--reply"
        :class="replyButtonClass"
        @click="handleReplyClick"
      >
        {{ $t('CONVERSATION.REPLYBOX.REPLY') }}
      </div>

      <div
        class="button--note"
        :class="noteButtonClass"
        @click="handleNoteClick"
      >
        {{ $t('CONVERSATION.REPLYBOX.PRIVATE_NOTE') }}
      </div>
    </div>
    <div v-if="isMessageLengthReachingThreshold" class="charlength">
      <div class="text-xs">
        <span :class="charLengthClass">
          {{ characterLengthWarning }}
        </span>
      </div>
    </div>
    <woot-button
      v-if="popoutReplyBox"
      variant="clear"
      icon="dismiss"
      color-scheme="secondary"
      class-names="popout-button"
      @click="$emit('click')"
    />
    <woot-button
      v-else
      variant="clear"
      icon="resize-large"
      color-scheme="secondary"
      class-names="popout-button"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import { REPLY_EDITOR_MODES, CHAR_LENGTH_WARNING } from './constants';
import {
  hasPressedAltAndPKey,
  hasPressedAltAndLKey,
} from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
export default {
  name: 'ReplyTopPanel',
  mixins: [eventListenerMixins],
  props: {
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    setReplyMode: {
      type: Function,
      default: () => {},
    },
    isMessageLengthReachingThreshold: {
      type: Boolean,
      default: () => false,
    },
    charactersRemaining: {
      type: Number,
      default: () => 0,
    },
    popoutReplyBox: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    replyButtonClass() {
      return {
        'is-active': this.mode === REPLY_EDITOR_MODES.REPLY,
      };
    },
    noteButtonClass() {
      return {
        'is-active': this.mode === REPLY_EDITOR_MODES.NOTE,
      };
    },
    charLengthClass() {
      return this.charactersRemaining < 0 ? 'text-red-600' : 'text-slate-600';
    },
    characterLengthWarning() {
      return this.charactersRemaining < 0
        ? `${-this.charactersRemaining} ${CHAR_LENGTH_WARNING.NEGATIVE}`
        : `${this.charactersRemaining} ${CHAR_LENGTH_WARNING.UNDER_50}`;
    },
  },
  methods: {
    handleKeyEvents(e) {
      if (hasPressedAltAndPKey(e)) {
        this.handleNoteClick();
      }
      if (hasPressedAltAndLKey(e)) {
        this.handleReplyClick();
      }
    },
    handleReplyClick() {
      this.setReplyMode(REPLY_EDITOR_MODES.REPLY);
    },
    handleNoteClick() {
      this.setReplyMode(REPLY_EDITOR_MODES.NOTE);
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-top {
  @apply flex border-0 p-0 m-0;

  .button--reply,
  .button--note {
    @apply flex items-center justify-center py-2.5 px-4 cursor-pointer text-sm leading-none text-secondary;

    &.is-active {
      @apply bg-white dark:bg-primary font-semibold text-accent dark:text-accent-dark relative;

      &:hover {
        @apply text-accent dark:text-accent-dark cursor-default;
      }

      &:after {
        @apply bg-white dark:bg-primary;
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 3px;
        border: 0;
        z-index: 5;
      }
    }
  }

  .button--reply {
    @apply border-r rounded-none border-b-0 border-l-0 border-t-0 border-neutral-medium dark:border-neutral-dark-medium;

    &:hover {
      @apply bg-white dark:bg-primary text-accent dark:text-accent-dark;
    }
  }
  .button--note {
    @apply border-l-0 rounded-none;

    &.is-active {
      @apply border-r border-b-0 bg-yellow-100 dark:bg-yellow-800 border-t-0 border-neutral-medium dark:border-neutral-dark-medium text-yellow-700 dark:text-[#ebbc00];

      &:hover {
        @apply text-yellow-700 dark:text-[#ebbc00];
      }

      &:after {
        @apply bg-yellow-100 dark:bg-yellow-800;
      }
    }

    &:hover {
      @apply bg-yellow-100 dark:bg-yellow-800 text-yellow-700 dark:text-[#ebbc00];
    }
  }
}

.charlength {
  @apply flex items-center my-0 mx-0;
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
}
</style>
