<template>
  <div
    class="flex flex-1 h-full justify-between flex-col m-0 bg-neutral-subtle dark:bg-primary"
  >
    <ai-header
      :button-route="buttonRoute"
      :header-title="$t(headerTitle)"
      :button-text="$t(headerButtonText)"
      :show-back-button="showBackButton"
      :back-url="backUrl"
      :show-new-button="showNewButton"
    />
    <keep-alive v-if="keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
  </div>
</template>

<script>
import AiHeader from './AiHeader.vue';

export default {
  components: {
    AiHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    headerButtonText: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    newButtonRoutes: {
      type: Array,
      default: () => [],
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    buttonRoute: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSubmitButtonDisabled: false,
    };
  },
  computed: {
    showNewButton() {
      return this.newButtonRoutes.length !== 0 && !this.showBackButton;
    },
  },
};
</script>
