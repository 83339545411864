<template>
  <div class="w-[32px] h-[32px]">
    <img :src="source" :alt="name" />
  </div>
</template>
<script>
export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>
