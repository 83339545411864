<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal
    :show.sync="show"
    :on-close="onClose"
    size="min-h-[75vh] !w-4/6 flex flex-col"
  >
    <woot-modal-header :header-title="modelTitle" />

    <div class="order-cancel flex-1">
      <div class="order-cancel-content">
        <div class="mt-6">
          <div
            class="flex items-center p-4 bg-neutral-subtle dark:bg-primary rounded-lg"
          >
            <div class="flex items-center gap-3 w-1/2">
              <div class="kpi">
                <div class="kpi__headline">
                  {{ order.created_at }}
                </div>
                <div class="kpi__tagline">
                  {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.ORDER_DATE') }}
                </div>
              </div>
              <div class="kpi">
                <div class="kpi__headline">{{ order.line_items.length }}</div>
                <div class="kpi__tagline">
                  {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.TOTAL_ITEMS') }}
                </div>
              </div>
            </div>
            <div class="flex items-center w-1/2">
              <div class="tag-wrapper">
                <div class="tag-wrapper__item">
                  <div class="tag-wrapper__label">
                    {{ order.financial_status }}
                  </div>
                </div>
                <div class="tag-wrapper__item">
                  <div
                    class="tag-wrapper__label"
                    :class="
                      !order.fulfillment_status
                        ? 'tag-wrapper__label--warning'
                        : 'tag-wrapper__label--fulfilled'
                    "
                  >
                    {{ order.fulfillment_status || 'unfulfilled' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr
                class="border-b border-neutral-high dark:border-neutral-dark-high"
              >
                <th width="41%">
                  {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.PRODUCT') }}
                </th>
                <th width="21%" class="text-right">
                  {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.ITEM_PRICE') }}
                </th>
                <th width="17%" class="text-center">
                  {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.QTY') }}
                </th>
                <th width="21%" class="text-right">
                  {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.ITEM_TOTAL') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <div class="text-primary dark:text-primary-dark text-sm">
                    {{ item.quantity }} X {{ item.name }}
                  </div>
                  <div class="text-secondary dark:text-secondary-dark text-sm">
                    {{
                      $t('SHOPIFY_PANEL.CANCEL_MODAL.SKU', { sku: item.sku })
                    }}
                  </div>
                </td>
                <td>
                  <div class="text-right text-sm">
                    {{ amountWithCurrency(item.price) }}
                  </div>
                </td>
                <td>
                  <div class="flex items-center justify-center gap-2">
                    {{ item.quantity }}
                  </div>
                </td>
                <td>
                  <div class="text-right font-medium">
                    {{
                      amountWithCurrency(
                        (item.quantity * item.price).toFixed(2)
                      )
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <callout
          v-if="cancelError"
          is-danger
          role="alert"
          :desc="cancelError"
          class="mb-4"
        />
      </div>
      <div class="order-cancel-summary">
        <div class="order-cancel-summary-content">
          <div
            class="text-primary dark:text-primary-dark mb-4 text-xl font-semibold uppercase tracking-[1px]"
          >
            Summary
          </div>
          <table>
            <tbody>
              <tr>
                <td>Items subtotal</td>
                <td class="text-right">
                  {{ calcItemsSubtotal() }}
                </td>
              </tr>
              <tr>
                <td>Tax</td>
                <td class="text-right">
                  {{ amountWithCurrency(order.current_total_tax || 0.0) }}
                </td>
              </tr>
              <tr>
                <td>Discounts</td>
                <td class="text-right">
                  {{ amountWithCurrency(order.current_total_discounts || 0.0) }}
                </td>
              </tr>
              <tr>
                <td>
                  Shipping
                  <span class="text-secondary dark:text-secondary-dark">
                    ({{ amountWithCurrency(shippingAmount) }})
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <label for="reason" class="flex">
        <span>{{ $t('SHOPIFY_PANEL.CANCEL_MODAL.CANCELLATION_REASON') }}</span>
      </label>
      <select id="reason" v-model="reason" class="max-w-max mb-0">
        <option v-for="r in cancellationReasons" :key="r" :value="r">
          {{ r[0].toUpperCase() + r.substring(1) }}
        </option>
      </select>
      <label for="restockItems">
        <input
          id="restockItems"
          v-model="restock"
          type="checkbox"
          value="false"
        />
        {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.RESTOCK_ITEMS') }}
      </label>

      <label for="sendNotification">
        <input
          id="sendNotification"
          v-model="email"
          type="checkbox"
          value="false"
        />
        {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.SEND_NOTIFICATION') }}
      </label>

      <button :disabled="isDisabled" class="button primary" @click="onSubmit">
        <span class="mr-1">
          {{ $t('SHOPIFY_PANEL.CANCEL_MODAL.BUTTON_CAPTION') }}
        </span>
        <spinner v-if="isCancelling" color-scheme="primary" size="small" />
      </button>
    </div>
  </woot-modal>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { CANCEL_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import Spinner from 'shared/components/Spinner.vue';
import Callout from 'shared/components/Callout.vue';
import shopifyMixin from 'dashboard/mixins/shopifyMixin';

export default {
  components: {
    Spinner,
    Callout,
  },
  mixins: [alertMixin, shopifyMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: false,
      restock: false,
      reason: '',
      isCancelling: false,
      shippingAmount: 0.0,
      totalAmount: 0.0,
      cancelError: '',
      items: this.order.line_items.map(
        item => {
          return { ...item, refund_quantity: 0 };
        } // pass prop "order.line_items" to data and add new property "refund_quantity" to calc refund total
      ),
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      uiFlags: 'cancel/getUIFlags',
    }),
    modelTitle() {
      return `${this.$t('SHOPIFY_PANEL.CANCEL_MODAL.TITLE')} ${
        this.order.name
      }`;
    },
    console: () => console,
    moment: () => moment,
    isDisabled() {
      return (this.reason || '').length === 0;
    },
    cancellationReasons() {
      return ['customer', 'inventory', 'fraud', 'declined', 'other'];
    },
  },
  mounted() {
    this.fetchOrderInfo();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    amountWithCurrency(amount) {
      return `${this.currency_symbol(this.order.currency)}${amount}`;
    },
    calcTotal() {
      return this.amountWithCurrency(this.refundTotalAmount);
    },
    actualAmount() {
      if (this.manualAmount > 0) {
        return this.amountWithCurrency(this.manualAmount);
      }
      return this.calcTotal();
    },
    calcItemsSubtotal() {
      let totalPrice = 0.0;
      // eslint-disable-next-line
      for (let item of this.items) {
        totalPrice += item.quantity * item.price;
      }
      return this.amountWithCurrency(totalPrice);
    },
    async onSubmit() {
      this.cancelError = '';
      this.isCancelling = true;
      this.$track(CANCEL_EVENTS.CREATE);
      const data = {
        restock: this.restock,
        reason: this.reason,
        email: this.email,
        order_id: this.order.id,
      };

      await this.$store
        .dispatch('cancel', {
          conversationId: this.currentChat.id,
          params: data,
        })
        .then(() => {
          this.showAlert(
            this.$t('SHOPIFY_PANEL.CANCEL_MODAL.FORM.SUCCESS_MESSAGE')
          );
          this.$store.dispatch('fetchShopifyData', this.currentChat.id);
          this.onClose();
        })
        .catch(error => {
          const errorMessage = error.response
            ? error.response.data.error
            : undefined;
          this.cancelError = errorMessage;
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE'));
        });
      this.isCancelling = false;
    },

    async submit() {
      this.isCancelling = true;
      this.$track(CANCEL_EVENTS.CREATE);
      const data = {
        email: this.email,
        restock: this.restock,
        reason: this.reason,
        order_id: this.order.id,
      };

      await this.$store
        .dispatch('cancel', {
          conversationId: this.currentChat.id,
          params: data,
        })
        .then(() => {
          this.showAlert(
            this.$t('SHOPIFY_PANEL.CANCEL_MODAL.FORM.SUCCESS_MESSAGE')
          );
          this.$store.dispatch('fetchShopifyData', this.currentChat.id);
        })
        .catch(() => {
          this.showAlert(
            this.$t('SHOPIFY_PANEL.CANCEL_MODAL.FORM.ERROR_MESSAGE')
          );
        });
      this.isCancelling = false;
      this.onClose();
    },

    async fetchOrderInfo() {
      if (!this.currentChat.id) {
        return;
      }
      await this.$store
        .dispatch('refundInfo', {
          conversationId: this.currentChat.id,
          params: {
            order_id: this.order.id,
            refund_items: this.items,
          },
        })
        .then(result => {
          this.amount = result.amount;
          this.refundTotalAmount = result.refund_total_amount;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// Adjust modal container
::v-deep {
  .modal-container {
    @apply w-[85%] max-h-[90vh] rounded-md;
  }
}

.modal-container {
  input[type='checkbox'] {
    @apply mb-0 mr-1;
  }

  form {
    @apply p-0;
  }

  .modal-footer {
    @apply flex items-center justify-end px-8 py-4 bg-neutral-subtle dark:bg-primary;
  }

  .modal-body {
    @apply px-8 pt-4 pb-8;
  }
}

.order-cancel {
  @apply flex gap-x-8 px-8;
}

.order-cancel-content {
  @apply basis-3/4 grow h-full flex flex-col;
}

.order-cancel-summary {
  @apply basis-1/4 bg-neutral-subtle dark:bg-neutral-dark-medium rounded-lg p-5 flex flex-col h-full sticky top-6 mt-6 mb-6;

  table {
    td {
      @apply px-0;
    }
  }
  tr {
    @apply border-0;
  }
  tr.cancel-total-wrapper {
    @apply border-t border-neutral-high dark:border-neutral-dark-high;
  }
}

.order-cancel-summary-content {
  @apply grow overflow-y-auto;
}

.order-summary-foot {
  @apply flex justify-between items-center pb-4 pt-4 border-t border-neutral-high dark:border-neutral-dark-high;
}

.order-summary {
  @apply flex gap-x-1 flex-wrap;
}

.order-summary-actions {
  @apply flex justify-end pt-2 pb-2;
}

.kpi {
  @apply flex flex-col basis-1 grow bg-neutral dark:bg-neutral-dark-light rounded-lg px-3 py-2;

  &__headline {
    @apply text-lg text-primary dark:text-primary-dark;
  }

  &__tagline {
    @apply text-[13px] text-secondary dark:text-secondary-dark;
  }
}

.tag-wrapper {
  @apply flex flex-wrap items-center justify-end w-full;

  &__item {
    @apply flex items-center ml-3 mb-1.5 mt-1.5;
  }

  &__label {
    @apply flex items-center bg-secondary-low dark:bg-secondary-dark-low text-primary dark:text-stategrey text-[13px] pl-2 pr-2 border-0 rounded-[2px] select-none;

    &--warning {
      @apply dark:text-stategrey text-[#746D2D] bg-yellow-100 dark:bg-[#403D20];
    }
  }

  &__icon {
    @apply relative w-[10px] h-[10px] ml-2.5 p-[6px] transition-all ease-in-out duration-200 cursor-pointer rounded-[1px] text-primary dark:text-stategrey;

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#25292B];
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }
}
</style>
