import { frontendURL } from '../../../../helper/URLHelper';

const notifications = accountId => ({
  parentNav: 'notifications',
  routes: ['notifications_index'],
  menuItems: [
    {
      label: 'NOTIFICATIONS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/notifications`),
      toStateName: 'notifications_index',
    },
  ],
});

export default notifications;
