<template>
  <modal
    :show="show"
    size="!w-auto !rounded-md border-1 border-secondary"
    :close-button-text="$t('SIDEBAR.ONBOARDING.HIDE')"
    :on-close="onClose"
  >
    <div class="onboarding">
      <div class="onboarding__body">
        <OnboardingSteps :steps="onboardingSteps" />
      </div>
    </div>
  </modal>
</template>

<script>
import onboardingMixin from 'dashboard/mixins/onboardingMixin';
import accountMixin from 'dashboard/mixins/account';
import Modal from 'dashboard/components/Modal';
import OnboardingSteps from '../../OnboardingSteps.vue';

export default {
  components: {
    Modal,
    OnboardingSteps,
  },
  mixins: [accountMixin, onboardingMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$root.$emit('onboarding-modal.hide');
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding {
  @apply w-full h-full flex justify-center items-center px-5 py-3;

  &__body {
    @apply basis-[800px];
  }
}
</style>
