import { frontendURL } from '../../../../helper/URLHelper';

const reports = accountId => ({
  parentNav: 'ai',
  routes: [
    'ai_home',
    'ai_intents',
    'ai_training',
    'ai_intents_new',
    'ai_intents_edit',
  ],
  menuItems: [
    {
      label: 'INTENT.TITLE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/ai/intents`),
      toStateName: 'ai_intents',
      forceLightColor: true,
    },
    {
      label: 'TRAINING_GROUND.TITLE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/ai/training`),
      toStateName: 'ai_training',
      forceLightColor: true,
      isDisabled: true, // temporary disabled training ground page
    },
  ],
});

export default reports;
