<template>
  <div class="conversation--details">
    <contact-details-item
      v-if="referer"
      :title="$t('CONTACT_PANEL.INITIATED_FROM')"
      :value="referer"
      class="conversation-attribute"
    >
      <a :href="referer" rel="noopener noreferrer nofollow" target="_blank">
        {{ referer }}
      </a>
    </contact-details-item>
    <contact-details-item
      v-if="initiatedAt"
      :title="$t('CONTACT_PANEL.INITIATED_AT')"
      :value="initiatedAt.timestamp"
      class="conversation-attribute"
    />
    <contact-details-item
      v-if="browserLanguage"
      :title="$t('CONTACT_PANEL.BROWSER_LANGUAGE')"
      :value="browserLanguage"
      class="conversation-attribute"
    />
    <custom-attributes
      attribute-type="conversation_attribute"
      attribute-class="conversation-attribute"
      :class="customAttributeRowClass"
    />
    <custom-attribute-selector
      v-if="$featureFlags['show-custom-attributes']"
      attribute-type="conversation_attribute"
    />
  </div>
</template>

<script>
import { getLanguageName } from '../../../components/widgets/conversation/advancedFilterItems/languages';
import ContactDetailsItem from './ContactDetailsItem.vue';
import CustomAttributes from './customAttributes/CustomAttributes.vue';
import CustomAttributeSelector from './customAttributes/CustomAttributeSelector.vue';

export default {
  components: {
    ContactDetailsItem,
    CustomAttributes,
    CustomAttributeSelector,
  },
  props: {
    conversationAttributes: {
      type: Object,
      default: () => ({}),
    },
    contactAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    referer() {
      return this.conversationAttributes.referer;
    },
    initiatedAt() {
      return this.conversationAttributes.initiated_at;
    },
    browserLanguage() {
      return getLanguageName(this.conversationAttributes.browser_language);
    },
    customAttributeRowClass() {
      const attributes = ['initiatedAt', 'referer', 'browserLanguage'];
      const availableAttributes = attributes.filter(
        attribute => !!this[attribute]
      );
      return availableAttributes.length % 2 === 0 ? 'even' : 'odd';
    },
  },
};
</script>
<style scoped lang="scss">
.conversation-attribute {
  &:nth-child(2n) {
    @apply bg-neutral-subtle dark:bg-[#25292B] rounded-lg px-2 py-2;
  }
}
</style>
