<template>
  <div class="dropdown-wrap">
    <div class="mb-2 flex-shrink-0 flex-grow-0 flex-auto max-h-8 relative">
      <input
        ref="searchbar"
        v-model="searchQuery"
        type="text"
        class="search-input !rounded-[0.3125rem]"
        :placeholder="inputPlaceholder"
        @input="debouncedHandleInput"
      />
    </div>
    <div class="h-full overflow-y-auto">
      <div v-if="isLoading" class="w-full flex justify-center items-center">
        <woot-loading-state size="small" class="p-0 pt-4" />
      </div>
      <template v-else>
        <woot-dropdown-menu>
          <woot-dropdown-item
            v-for="option in options"
            :key="option.id"
            class="first:mt-[0.02rem] h-[38px]"
          >
            <woot-button
              class="multiselect-dropdown--item !shadow-none"
              :variant="isActive(option) ? 'hollow' : 'clear'"
              color-scheme="secondary"
              :class="{ active: isActive(option) }"
              @click="() => onclick(option)"
            >
              <div class="flex items-center">
                <div class="flex items-center justify-between w-full min-w-0">
                  <span
                    class="leading-4 my-0 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
                    :title="option.title"
                  >
                    {{ option.title }}
                  </span>
                  <fluent-icon v-if="isActive(option)" icon="checkmark" />
                </div>
              </div>
            </woot-button>
          </woot-dropdown-item>
        </woot-dropdown-menu>
        <h4
          v-if="noResult"
          class="w-full justify-center items-center flex text-secondary dark:text-secondary-dark py-2 px-2.5 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
        >
          {{ noSearchResult }}
        </h4>
      </template>
    </div>
    <div
      class="pt-1.5 border-t border-neutral-high dark:border-secondary-dark-low"
    >
      <div class="dropdown-action" @click="handleActionButton">
        <fluent-icon icon="add" class="dropdown-action__icon" />
        <div class="dropdown-action__text">Add new intent</div>
      </div>
    </div>
  </div>
</template>

<script>
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import { debounce } from '@chatwoot/utils';

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    inputPlaceholder: {
      type: String,
      default: 'Search',
    },
    noSearchResult: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: '',
      debouncedHandleInput: () => {},
    };
  },

  computed: {
    noResult() {
      return this.options.length === 0;
    },
  },

  created() {
    this.debouncedHandleInput = debounce(this.handleInput, 300);
  },

  mounted() {
    this.focusInput();
  },

  methods: {
    onclick(option) {
      this.$emit('click', option);
    },
    focusInput() {
      this.$refs.searchbar.focus();
    },
    isActive(option) {
      return this.selectedItems.some(item => item && option.id === item.id);
    },
    handleInput(e) {
      this.searchQuery = e.target.value;
      this.$emit('search', this.searchQuery);
    },
    handleActionButton() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wrap {
  @apply w-full flex flex-col h-[calc(100%+2px)];
}

.search-input {
  @apply m-0 w-full border h-[38px] text-sm text-slate-700 dark:text-slate-100 rounded-md focus:border-accent dark:focus:border-accent-dark bg-white dark:bg-primary border-neutral-medium dark:border-neutral-dark-medium;
}

.multiselect-dropdown--item {
  @apply justify-between w-full;
}

.dropdown-action {
  @apply flex items-center leading-4 w-full text-base cursor-pointer px-2 py-2 text-accent dark:text-accent-dark my-0.5 rounded-[4px];

  &:hover {
    @apply bg-accent-low dark:bg-accent-dark-low;
  }

  &__icon {
    @apply w-[16px] h-[16px] relative mr-2;
  }
  &__text {
    @apply leading-none;
  }
}
</style>
