<template>
  <inbox-reconnection-required class="mb-5 mt-5" @reauthorize="initAircallAuth" />
</template>

<script>
/* global FB */
import InboxReconnectionRequired from '../components/InboxReconnectionRequired';
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from 'dashboard/api/account';

export default {
  components: {
    InboxReconnectionRequired,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inboxId() {
      return this.inbox.id;
    },
  },
  methods: {
    async initAircallAuth() {
      await AccountAPI.createAircallAuthURL({reauth: true, inbox_id: this.inboxId,})
        .then(response => {
          window.location.href = response.data.aircall_auth_url;
          'Failed to get AirCall auth url';
        })
        .catch(error => {
          this.showAlert('Failed to get AirCall auth url', error);
        });
    },
  },
};
</script>
