<template>
  <inbox-reconnection-required class="mb-5 mt-5" @reauthorize="initGmailAuth" />
</template>

<script>
/* global FB */
import InboxReconnectionRequired from '../components/InboxReconnectionRequired';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    InboxReconnectionRequired,
  },
  mixins: [alertMixin],
  data() {
    return {
      errorStateMessage: '',
    };
  },
  props: {
    inbox: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inboxId() {
      return this.inbox.id;
    },
  },
  mounted() {
    this.loadGoogleLibrary()
      .then(() => console.log('Google library loaded successfully'))
      .catch(error => {
        this.hasError = true;
        this.errorStateMessage = 'Failed to load Google library';
        console.error(this.errorStateMessage, error);
      });
  },
  methods: {
    initGmailAuth() {
      try {
        const client = google.accounts.oauth2.initCodeClient({
          client_id: window.chatwootConfig.gmailOAuthClientId,
          scope:
            'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send',
          include_granted_scopes: false,
          ux_mode: 'popup',
          callback: this.handleAuthResponse,
        });

        client.requestCode();
      } catch (error) {
        this.errorStateMessage = 'Initialization failed';
      }
    },

    loadGoogleLibrary() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    handleAuthResponse(response) {
      if (response.code) {
        this.reauthorizeGMail(response.code);
      } else {
        this.errorStateMessage = 'Authentication failed';
        this.showAlert(this.errorStateMessage);
      }
    },
    async reauthorizeGMail(omniauthToken) {
      await this.$store.dispatch('inboxes/reauthorizeGMail', {
        code: omniauthToken,
        inbox_id: this.inboxId,
      });

      this.showAlert(this.$t('INBOX_MGMT.GMAIL_REAUTHORIZE.MESSAGE_SUCCESS'));
    },
  },
};
</script>
