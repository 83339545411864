<template>
  <div
    v-if="hasSecondaryMenu"
    class="secondary-panel"
    :class="isSidebarExpanded ? 'w-[220px]' : 'w-[64px]'"
  >
    <new-account-dropdown
      :is-sidebar-expanded="isSidebarExpanded"
      :logo-source="logoSource"
      :name="installationName"
      :account-id="accountId"
      :primary-menu-items="primaryMenuItems"
      :primary-active-menu-item="primaryActiveMenuItem"
      @show-create-account-modal="$emit('show-create-account-modal')"
    />

    <transition name="menu-list" class="pt-2 list-none ml-0 mb-0 flex-1">
      <div
        v-if="isNotInHomepage && isSidebarExpanded"
        @click="handleBackToDashboard"
      >
        <div class="sidebar-action">
          <div class="sidebar-action__icon">
            <fluent-icon size="16" icon="chevron-left" type="outline" />
          </div>
          <div class="sidebar-action__text">
            {{ $t(`SIDEBAR.${getNamesOfPrimaryNavItems[0]}`) }}
          </div>
        </div>
      </div>
    </transition>

    <transition-group
      name="menu-list"
      tag="ul"
      class="pt-2 list-none ml-0 mb-0 flex-1"
    >
      <new-secondary-nav-item
        v-for="menuItem in accessibleMenuItems"
        :key="menuItem.toState"
        :menu-item="menuItem"
        :is-sidebar-expanded="isSidebarExpanded"
        :is-disabled="menuItem.isDisabled"
        :is-in-homepage="!isNotInHomepage"
      />
      <new-secondary-nav-item
        v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
        :key="menuItem.key"
        :menu-item="menuItem"
        :is-disabled="menuItem.isDisabled"
        :is-sidebar-expanded="isSidebarExpanded"
        :active-menu-key="activeMenuKey"
        :is-in-homepage="!isNotInHomepage"
        @add-label="showAddLabelPopup"
        @add-folder="showAddFolderPopup"
        @click="val => handleSecondaryNavClick(val)"
      />
    </transition-group>

    <!-- bottom section -->
    <new-notification-bell
      :is-sidebar-expanded="isSidebarExpanded"
      @open-notification-panel="openNotificationPanel"
    />
    <help-menu :is-sidebar-expanded="isSidebarExpanded" />
    <new-agent-details
      :is-sidebar-expanded="isSidebarExpanded"
      @toggle-menu="toggleOptions"
    />
    <options-menu
      :is-has-onboarding="!hideOnboarding"
      :is-sidebar-expanded="isSidebarExpanded"
      :show="showOptionsMenu"
      @toggle-accounts="toggleAccountModal"
      @show-support-chat-window="toggleSupportChatWindow"
      @key-shortcut-modal="$emit('key-shortcut-modal')"
      @close="toggleOptions"
    />
    <new-onboarding-view
      v-if="!hideOnboarding"
      :is-sidebar-expanded="isSidebarExpanded"
    />
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import NewSecondaryNavItem from './NewSecondaryNavItem.vue';
import NewAccountDropdown from './NewAccountDropdown.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';
import NewOnboardingView from 'dashboard/components/NewOnboardingView';

// bottom section
import wootConstants from 'dashboard/constants/globals';
import OptionsMenu from './OptionsMenu.vue';
import NewAgentDetails from './NewAgentDetails.vue';
import NewNotificationBell from './NewNotificationBell.vue';
import { ACCOUNT_EVENTS } from 'dashboard/helper/AnalyticsHelper/events';
import HelpMenu from './HelpMenu.vue';

export default {
  components: {
    NewSecondaryNavItem,
    NewAccountDropdown,
    NewOnboardingView,
    NewNotificationBell,
    OptionsMenu,
    NewAgentDetails,
    HelpMenu,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentRole: {
      type: String,
      default: '',
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    primaryMenuItems: {
      type: Array,
      default: () => [],
    },
    primaryActiveMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      activeMenuKey: '',
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      currentAccount: 'getCurrentAccount',
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const menuItemsFilteredByRole = this.menuConfig.menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );
      return menuItemsFilteredByRole.filter(item => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });
    },
    inboxSection() {
      return {
        icon: 'inboxes',
        iconSize: '19',
        viewBox: this.isSidebarExpanded ? '-0.8 0 16 16' : '0 0 16 16',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            is_meta_comments_inbox: inbox.is_meta_comments_inbox,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'tag',
        iconSize: '19',
        viewBox: this.isSidebarExpanded ? '-1.9 -0.5 18 18' : '-1 -0.5 18 18',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        dataTestid: 'sidebar-new-label-button',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'tag',
        iconSize: '19',
        viewBox: this.isSidebarExpanded ? '-1.9 -0.5 18 18' : '-1 -0.5 18 18',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'teams',
        iconSize: '19',
        viewBox: this.isSidebarExpanded ? '-0.8 0 16 16' : '0 0 16 16',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        iconSize: '19',
        viewBox: this.isSidebarExpanded ? '-0.8 0 16 16' : '0 0 16 16',
        label: 'CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        newLinkTag: 'NEW_VIEW',
        newLink: true,
        toState: frontendURL(`accounts/${this.accountId}/custom_view/`),
        showModalForNewItem: true,
        modalName: 'AddFolder',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        iconSize: '19',
        viewBox: this.isSidebarExpanded ? '-0.8 0 16 16' : '0 0 16 16',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [
        this.foldersSection,
        this.inboxSection,
        this.labelSection,
      ];
      let contactMenuItems = [this.contactLabelSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
      };
    },
    hideOnboarding() {
      if (!this.currentAccount) return true;

      return this.currentAccount.hide_onboarding;
    },
    currentRoute() {
      return this.$route;
    },
    isNotInHomepage() {
      const currentPath = this.currentRoute.path;
      const primaryStates =
        this.primaryMenuItems?.map(item => item.toState) || [];

      return (
        primaryStates.some(state => {
          // Check if the current path starts with the state
          if (currentPath.startsWith(state)) {
            return true;
          }

          // Check for nested routes (like labels)
          const stateSuffix = state.split('/').pop();
          return (
            currentPath.includes('/labels/') &&
            currentPath.endsWith(stateSuffix)
          );
        }) && this.currentRoute.name !== 'home'
      );
    },
    getNamesOfPrimaryNavItems() {
      const currentPath = this.currentRoute.path;

      if (currentPath.includes('/labels/')) {
        // Handle paths related to labels, including the /settings/labels/list case
        if (currentPath.includes('/settings/labels/list')) {
          const matchingItem = this.primaryMenuItems.find(item =>
            currentPath.startsWith(item.toState)
          );
          return matchingItem ? [matchingItem.label] : [];
        }

        const matchingItem = this.primaryMenuItems.find(item =>
          currentPath.endsWith(item.toState.split('/').pop())
        );
        return matchingItem ? [matchingItem.label] : [];
      }

      // For regular paths, use the label as a name (original version)
      return this.primaryMenuItems
        .filter(item => currentPath.startsWith(item.toState))
        .map(item => item.label);
    },
  },
  methods: {
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    showAddFolderPopup() {
      this.$emit('add-folder');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    handleBackToDashboard() {
      this.$router.push({
        name: 'home',
      });
    },
    handleSecondaryNavClick(key) {
      const additionalSecondaryMenu =
        this.additionalSecondaryMenuItems[this.menuConfig.parentNav];
      const parent = additionalSecondaryMenu.find(child => child.key === key);
      this.activeMenuKey = parent.key;
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-panel {
  @apply h-full overflow-auto flex flex-col bg-neutral-subtle dark:bg-primary border-r rtl:border-r-0 rtl:border-l text-sm pb-4 border-neutral-medium dark:border-neutral-dark-medium overflow-x-hidden px-2;
}

.sidebar-action {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  p-2
  m-0
  h-[33px]
  text-sm
  mt-4
  text-secondary
  dark:text-secondary
  hover:text-secondary
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  border
  border-transparent
  cursor-pointer;

  &__icon {
    @apply w-[16px] h-[16px] relative mr-1.5 rtl:mr-0 rtl:ml-1.5;
  }

  &__text {
    @apply leading-none;
  }
}
</style>
