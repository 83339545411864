var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row gap-8 flex-shrink min-w-0 p-6 overflow-auto h-full"},[_c('div',{staticClass:"w-[60%]"},[_c('div',{staticClass:"mb-2 text-sm"},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR.INTENT.TITLE_LABEL'))+"\n    ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.intentTitle),expression:"intentTitle"}],staticClass:"input-text",class:{
        'is-focused': _vm.focusedField === 'titleField',
      },attrs:{"type":"text","placeholder":_vm.$t('SIDEBAR.INTENT.QUESTION.PLACEHOLDER')},domProps:{"value":(_vm.intentTitle)},on:{"focus":function($event){return _vm.onFocus('titleField')},"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.intentTitle=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"mb-2 text-sm"},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR.INTENT.DESCRIPTION.LABEL'))+"\n    ")]),_vm._v(" "),_c('resizable-text-area',{staticClass:"textarea",class:{
        'is-focused': _vm.focusedField === 'descriptionField',
      },attrs:{"placeholder":_vm.$t('SIDEBAR.INTENT.QUESTION.PLACEHOLDER')},on:{"focus":function($event){return _vm.onFocus('descriptionField')},"blur":_vm.onBlur},model:{value:(_vm.intentDescription),callback:function ($$v) {_vm.intentDescription=$$v},expression:"intentDescription"}}),_vm._v(" "),_c('div',{staticClass:"mb-2 text-sm"},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR.INTENT.RESPOND.LABEL'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"editor",class:{
        'is-focused': _vm.focusedField === 'instructionField',
      }},[_c('woot-message-editor',{staticClass:"input",attrs:{"editor-id":_vm.getEditorId,"placeholder":_vm.$t('SIDEBAR.INTENT.RESPOND.PLACEHOLDER')},on:{"focus":function($event){return _vm.onFocus('instructionField')},"blur":_vm.onBlur},model:{value:(_vm.intentInstruction),callback:function ($$v) {_vm.intentInstruction=$$v},expression:"intentInstruction"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"w-[34%]"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.$t('SIDEBAR.INTENT.ADD_NEW_GUIDE')),expression:"$t('SIDEBAR.INTENT.ADD_NEW_GUIDE')"}]})]),_vm._v(" "),_c('woot-button',{staticClass:"button--fixed-top",attrs:{"size":"small","color-scheme":"primary","icon":"add-circle","is-disabled":_vm.isDisabled},on:{"click":_vm.handleSubmit}},[_vm._v("\n    "+_vm._s(_vm.$t('SIDEBAR.INTENT.SAVE'))+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }