var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ExpandableBox',{attrs:{"expanded":_vm.expanded,"heading":_vm.$featureFlags['show-flows-ui']
        ? _vm.$t('CHAT_WIDGET_SETTINGS.QUICK_LINKS_AND_FLOWS')
        : _vm.$t('CHAT_WIDGET_SETTINGS.FEATUREFLAGS.QUICK_LINKS_AND_FLOWS'),"subheading":_vm.$featureFlags['show-flows-ui']
        ? _vm.$t('CHAT_WIDGET_SETTINGS.SELECT_HINT')
        : _vm.$t('CHAT_WIDGET_SETTINGS.FEATUREFLAGS.SELECT_HINT')}},[_c('draggable',{staticClass:"border-t-0",attrs:{"tag":"div","list":_vm.resources,"handle":".handle"},on:{"start":function($event){_vm.dragging = true},"end":_vm.onDragEnd}},_vm._l((_vm.resources),function(resource){return _c('QuickLinkItem',{key:resource.id,staticClass:"draggable",attrs:{"item":resource}})}),1),_vm._v(" "),_c('a',{staticClass:"button hollow mt-5 rounded-lg h-auto",attrs:{"href":"#add","disabled":_vm.maxItemsReached},on:{"click":function($event){$event.preventDefault();return _vm.showAddModal()}}},[_c('Icon',{attrs:{"size":"24","icon":"add","type":"regular","icons":_vm.iconLib}}),_vm._v("\n      "+_vm._s(_vm.$featureFlags['show-flows-ui']
          ? _vm.$t('CHAT_WIDGET_SETTINGS.ADD_ITEM_BUTTON')
          : _vm.$t('CHAT_WIDGET_SETTINGS.FEATUREFLAGS.ADD_ITEM_BUTTON'))+"\n    ")],1),_vm._v(" "),(_vm.$featureFlags['show-flows-ui'])?_c('p',{staticClass:"text-black-600 mt-5"},[_vm._v("\n      "+_vm._s(_vm.$t('CHAT_WIDGET_SETTINGS.FLOW_BUILDER_HINT'))+"\n      "),_c('router-link',{attrs:{"to":_vm.addAccountScoping('settings/workflows/list')}},[_vm._v("\n        "+_vm._s(_vm.$t('CHAT_WIDGET_SETTINGS.FLOW_BUILDER'))+"\n      ")])],1):_c('p',{staticClass:"text-black-600 mt-5"},[_vm._v("\n      "+_vm._s(_vm.$t('CHAT_WIDGET_SETTINGS.FEATUREFLAGS.FLOW_BUILDER_HINT'))+"\n      "),_c('router-link',{attrs:{"to":_vm.addAccountScoping('portals')}},[_vm._v("\n        "+_vm._s(_vm.$t('CHAT_WIDGET_SETTINGS.FEATUREFLAGS.FLOW_BUILDER'))+"\n      ")])],1)],1),_vm._v(" "),_c('AddItemModal',{attrs:{"show":_vm.showModal,"max-items":_vm.maxItemsToAdd,"inbox-id":_vm.inboxId},on:{"closeModal":function($event){_vm.showModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }