import Wrapper from '../Wrapper.vue';
import Training from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/ai/training'),
      roles: ['administrator'],
      component: Wrapper,
      props: {
        headerTitle: 'SIDEBAR.TRAINING_GROUND.TITLE',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'ai_training',
          component: Training,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
