<template>
  <div class="signature-missing pointer-events-auto">
    <div class="signature-missing__body">
      <div class="signature-missing__text">
        {{ $t('CONVERSATION.FOOTER.MESSAGE_SIGNATURE_NOT_CONFIGURED') }}
        <div class="signature-missing__button" @click="openProfileSettings">
          {{ $t('CONVERSATION.FOOTER.CLICK_HERE') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openProfileSettings() {
      return this.$router.push({
        name: 'profile_settings_index',
        query: { section: 'signature' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-missing {
  @apply max-h-[8vh] border border-dashed border-slate-100 dark:border-slate-700 rounded-sm overflow-auto leading-normal bg-white dark:bg-primary mx-4;

  &__body {
    @apply flex items-center p-2;
  }

  &__text {
    @apply text-xs;
  }
  &__button {
    @apply text-xs text-accent dark:text-accent-dark cursor-pointer hover:text-primary dark:hover:text-primary-dark hover:underline inline-block;
  }
}
</style>
