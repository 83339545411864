<template>
  <div :class="hintClass">
    <div v-if="context === 'default'" class="hints__body">
      <key-icon icon-key="up" />
      <key-icon icon-key="down" />
      <div
        class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
      >
        {{ $t('KEYBOARD_SHORTCUTS.TITLE.NAVIGATE') }}
      </div>

      <key-icon icon-key="left" />
      <key-icon icon-key="right" />
      <div
        class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
      >
        {{ $t('KEYBOARD_SHORTCUTS.TITLE.CHANGE_TAB') }}
      </div>

      <key-icon icon-key="return" />
      <div
        class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
      >
        {{ $t('KEYBOARD_SHORTCUTS.TITLE.REPLY') }}
      </div>

      <template v-if="!isInConversationLobby">
        <key-icon icon-key="h" />
        <div
          class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
        >
          {{ $t('KEYBOARD_SHORTCUTS.TITLE.SNOOZE_CONVERSATION') }}
        </div>

        <key-icon icon-key="e" />
        <div
          class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
        >
          {{ $t('KEYBOARD_SHORTCUTS.TITLE.RESOLVE_CONVERSATION') }}
        </div>
      </template>
    </div>

    <div v-else-if="context === 'replying'" class="hints__body">
      <key-icon :icon-key="isUseMacOs ? 'meta' : 'ctrl'" />
      <key-icon icon-key="return" />
      <div
        class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
      >
        {{ $t('KEYBOARD_SHORTCUTS.TITLE.REPLY') }}
      </div>

      <key-icon icon-key="esc" />
      <div
        class="me-1.5 lg:me-8 text-[10px] leading-3 md:leading-normal lg:text-xs"
      >
        {{ $t('KEYBOARD_SHORTCUTS.TITLE.DEACTIVATE_TEXT_EDITOR') }}
      </div>
    </div>

    <!-- Dismiss button -->
    <div class="hints__footer" @click="dismiss">
      <div class="hints__dismiss">
        <Icon icon="dismiss" type="outline" size="16" :icons="iconLib" />
      </div>
    </div>
  </div>
</template>

<script>
import icons from 'shared/components/FluentIcon/dashboard-icons.json';
import Icon from 'shared/components/FluentIcon/Icon';
import KeyIcon from 'dashboard/components/KeyIcon';
import { isMacOs } from 'shared/helpers/osCheck';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';

export default {
  components: {
    Icon,
    KeyIcon,
  },
  mixins: [uiSettingsMixin],
  props: {
    context: {
      type: String,
      default: 'default',
      required: false,
    },
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
    isInConversationLobby: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconLib() {
      return icons;
    },
    isUseMacOs() {
      return isMacOs();
    },
    hintClass() {
      let baseClass = ['hints'];
      if (this.isSidebarOpen) {
        baseClass.push('hints--sidebar-open');
      }
      return baseClass;
    },
  },
  methods: {
    dismiss() {
      this.$store.dispatch('updateProfile', { hide_kb_hints: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.hints {
  @apply bg-neutral-subtle dark:bg-neutral-dark-light flex flex-row justify-center items-center pt-3 pb-2 px-5 text-xs fixed bottom-0 left-auto z-[5] h-[44px] border-t border-neutral-high dark:border-neutral-dark-medium;
  width: calc(100vw - 64px);

  @media (max-width: 1024px) {
    @apply px-2.5;
  }

  &__body {
    @apply flex items-center justify-center;
  }

  &__footer {
    @apply transition-all ease-in-out duration-200 w-6 h-6 absolute right-5 rounded cursor-pointer bg-neutral-light dark:bg-neutral-dark-light;

    &:hover {
      @apply bg-secondary-low dark:bg-secondary-dark-low;

      .hints__dismiss {
        @apply text-secondary dark:text-secondary-dark;
      }
    }

    // Based on SMALL_SCREEN_BREAKPOINT on wootConstants
    @media (max-width: 1024px) {
      @apply right-2;
    }
  }

  &__dismiss {
    @apply text-secondary dark:text-secondary-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all ease-in-out duration-200;
  }

  &--sidebar-open {
    width: calc(100vw - 220px);
  }
}
</style>
