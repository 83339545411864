import Wrapper from '../Wrapper.vue';
import Intents from './Index.vue';
import AddInstruction from './Add.vue';
import EditInstruction from './Edit.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/ai/intents'),
      roles: ['administrator'],
      component: Wrapper,
      props: params => {
        const showBackButton = params.name !== 'ai_intents';
        const addPage = params.name === 'ai_intents_new';
        const editPage = params.name === 'ai_intents_edit';

        let title;
        if (addPage) {
          title = 'SIDEBAR.INTENT.ADD_TITLE';
        } else if (editPage) {
          title = 'SIDEBAR.INTENT.EDIT_TITLE';
        } else {
          title = 'SIDEBAR.INTENT.TITLE';
        }

        return {
          headerTitle: title,
          headerButtonText: 'SIDEBAR.INTENT.ADD_NEW',
          newButtonRoutes: ['ai_intents_new'],
          showBackButton,
          buttonRoute: 'intents/new',
        };
      },
      children: [
        {
          path: '',
          name: 'ai_intents',
          component: Intents,
          roles: ['administrator'],
        },
        {
          path: 'new',
          name: 'ai_intents_new',
          roles: ['administrator'],
          component: AddInstruction,
        },
        {
          path: 'edit/:intent_id',
          name: 'ai_intents_edit',
          roles: ['administrator'],
          component: EditInstruction,
        },
      ],
    },
  ],
};
