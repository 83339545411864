var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]"},[(Object.keys(_vm.currentInbox.provider_config || {}).length)?[(_vm.lastStep === 1)?[_c('div',{staticClass:"w-full"},[_c('page-header',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.TITLE'),"header-content":_vm.isShowSimpleOption ? _vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.DESC') : undefined}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap -mx-2",class:_vm.isShowSimpleOption ? 'justify-center' : undefined},[(_vm.isShowSimpleOption)?_c('div',{staticClass:"w-1/2 px-2"},[_c('div',{staticClass:"flex flex-col h-full"},[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.HEADING')
              ),expression:"\n                $t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.HEADING')\n              "}],staticClass:"mb-4 text-primary dark:text-primary-dark"}),_vm._v(" "),_c('dns-card',{attrs:{"title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.TITLE'),"desc":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.DESC'),"capsule-text":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.TIME')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-primary dark:text-primary-dark font-medium mb-2 text-sm"},[_vm._v("\n                  "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.REQUIREMENT'))+"\n                ")]),_vm._v(" "),_c('ul',{staticClass:"text-sm"},_vm._l((_vm.$t(
                      'INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.REQUIREMENT.CONTENT'
                    )),function(item,index){return _c('li',{key:index},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),0)]},proxy:true},{key:"action",fn:function(){return [_c('woot-button',{attrs:{"is-expanded":""},on:{"click":function($event){return _vm.sendOption('simple')}}},[_vm._v("\n                  "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.SELECT'))+"\n                ")])]},proxy:true}],null,false,573492623)})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-1/2 px-2"},[_c('div',{staticClass:"flex flex-col h-full"},[(_vm.isShowSimpleOption)?_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.HEADING')
              ),expression:"\n                $t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.HEADING')\n              "}],staticClass:"mb-4 text-primary dark:text-primary-dark"}):_vm._e(),_vm._v(" "),_c('dns-card',{attrs:{"title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.TITLE'),"desc":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.DESC'),"capsule-text":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.TIME'),"capsule-variant":"secondary"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-primary dark:text-primary-dark font-medium mb-2 text-sm"},[_vm._v("\n                  "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.REQUIREMENT'))+"\n                ")]),_vm._v(" "),_c('ul',{staticClass:"text-sm"},_vm._l((_vm.$t(
                      'INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.REQUIREMENT.CONTENT'
                    )),function(item,index){return _c('li',{key:index},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),0)]},proxy:true},{key:"action",fn:function(){return [_c('woot-button',{attrs:{"is-expanded":""},on:{"click":function($event){return _vm.sendOption('advance')}}},[_vm._v("\n                  "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.SELECT'))+"\n                ")])]},proxy:true}],null,false,73071503)})],1)])])]:_vm._e(),_vm._v(" "),(_vm.lastStep === 2)?_c('div',{staticClass:"w-full"},[_c('page-header',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.TITLE', {
            email: _vm.currentInbox.email,
          }),"header-content":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.DESC')}}),_vm._v(" "),_c('dns-table',{staticClass:"mb-4",attrs:{"title":"DKIM","type":"TXT","host":_vm.getPostmarkDomain.dkim_host,"value":_vm.getPostmarkDomain.dkim_value,"is-inactive":_vm.isDkimInactive,"is-verified":_vm.getPostmarkDomain.dkim_verified || _vm.isDkimVerified,"is-error":_vm.isDkimError,"is-loading":_vm.isDkimLoading,"callout-title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_DKIM.TITLE'),"callout-desc":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_DKIM.DESC', {
            dkimDomainKey: _vm.getPostmarkDomain.dkim_host,
          })}}),_vm._v(" "),_c('dns-table',{staticClass:"mb-4",attrs:{"title":"Return-Path","type":"CNAME","host":_vm.getPostmarkDomain.return_path,"value":_vm.getPostmarkDomain.return_path_cname_value,"is-inactive":_vm.isPathInactive,"is-verified":_vm.getPostmarkDomain.return_path_verified || _vm.isPathVerified,"is-error":_vm.isPathError,"is-loading":_vm.isPathLoading,"callout-title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_RETURN_PATH.TITLE'),"callout-desc":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_RETURN_PATH.DESC', {
            pmBounces: _vm.getPostmarkDomain.return_path,
          })}}),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-2.5 mb-4"},[_c('div',{staticClass:"flex items-center justify-center gap-2.5"},[(!_vm.isPathVerified || !_vm.isDkimVerified)?_c('woot-button',{attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.handleVerify}},[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.VERIFY'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.isDnsVerified)?_c('woot-button',{on:{"click":_vm.handleSuccessVerified}},[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.CONTINUE'))+"\n          ")]):_vm._e()],1)])],1):_vm._e(),_vm._v(" "),(_vm.lastStep === 3)?_c('div',{staticClass:"flex flex-col h-[calc(100%-100px)] justify-center"},[_c('div',{staticClass:"w-[400px] px-2 m-auto"},[_c('dns-card',{attrs:{"title":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.VERIFY.TITLE'),"desc":_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.VERIFY.DESC', {
              email: _vm.currentInbox.email,
            })},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-primary dark:text-primary-dark font-medium mb-6 text-sm"},[_c('woot-button',{staticClass:"p-0 h-[inherit]",attrs:{"variant":"clear","disabled":_vm.isDisabled},on:{"click":_vm.handleResendEmail}},[_vm._v("\n                "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.RESEND'))+"\n              ")])],1)]},proxy:true},{key:"action",fn:function(){return [_c('woot-button',{attrs:{"is-expanded":""},on:{"click":_vm.gotoForwardEmail}},[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.AUTH_EMAIL.CONTINUE'))+"\n            ")])]},proxy:true}],null,false,3351028317)})],1)]):_vm._e()]:[_c('div',{staticClass:"p-4 border border-neutral-medium dark:border-neutral-dark-medium"},[_vm._v("\n      Unable to get Postmark domain.\n    ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }