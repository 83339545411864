<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] h-auto">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AIRCALL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.AIRCALL.DESC')"
    />
    <div v-if="hasError" class="aircall-error-state">
      {{ errorStateMessage }}
    </div>

    <a v-if="!airCallAuthorized"
      @click="initAircallAuth" class="aircall-action">

      <div class="aircall-action__image">
        <img src="/assets/images/dashboard/channels/aircall.png" alt="">
      </div>
      <div class="aircall-action__text">
        {{ $t('INBOX_MGMT.ADD.AIRCALL.ACTION_TEXT') }}
      </div>
    </a>

    <form
      v-if="airCallAuthorized"
      class="mx-0 flex flex-wrap"
      @submit.prevent="createChannel"
    >
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.AIRCALL.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.AIRCALL.INBOX_NAME.PLACEHOLDER')"
          />
        </label>
      </div>
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.GMAIL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import AccountAPI from 'dashboard/api/account';
import alertMixin from 'shared/mixins/alertMixin';
import router from "../../../../index";

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      hasError: false,
      errorStateMessage: '',
      airCallAuthorized: false,
      airCallAuthUrl: '',
      inboxName: '',
      code: '',
      state: ''
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
  },
  validations: {
    inboxName: { required },
  },
  mounted() {
    this.code = this.$route.query.code
    this.state = this.$route.query.state

    if (this.code && this.state) {
      this.airCallAuthorized = true;
    } else {
      this.airCallAuthorized = false;
    }

    this.getAuthUrl();
  },
  methods: {
    console: () => console,
    async getAuthUrl() {
       await AccountAPI.createAircallAuthURL()
         .then(response => {
            this.airCallAuthUrl = response.data.aircall_auth_url;
         })
          .catch(error => {
            this.hasError = true;
            this.errorStateMessage = 'Failed to get AirCall auth url';
            console.error(this.errorStateMessage, error);
          });
    },
    async initAircallAuth() {
      try {
        if(!this.airCallAuthUrl){
          throw new Error('Failed to get airCall Auth Url');
        }
        window.location.href = this.airCallAuthUrl;
      } catch (error) {
        this.hasError = true;
        this.errorStateMessage = 'Initialization failed';
        console.error(this.errorStateMessage, error);
      }
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        await this.exchangeAuthCode(this.code, this.state, this.inboxName);
      } catch (error) {
        this.hasError = true;
        this.errorStateMessage = error?.message;
        this.showAlert(this.errorStateMessage);
      }
    },
    async exchangeAuthCode(code, state, inbox_name) {
      const inbox = await this.$store.dispatch('inboxes/createAirCallChannel', {
        code,
        state,
        inbox_name,
      });

      router.replace({
        name: 'settings_inboxes_aircall_success',
        params: {
          page: 'new',
          inbox_id: inbox.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .aircall-action {
    @apply inline-flex items-center justify-center border border-neutral-medium border-solid dark:border-neutral-dark-medium rounded-md h-[44px] px-4 py-3 cursor-pointer transition-all ease-in-out duration-200;

    &:hover {
      @apply border-accent dark:border-accent-dark shadow-medium;
    }

    &__image {
      @apply w-[20px] h-auto mr-2;
    }

    &__text {
      @apply text-sm text-secondary dark:text-secondary-dark;
    }
  }
  .aircall-error-state {
    @apply border bg-error bg-opacity-[0.2] border-error-medium dark:border-error-dark-medium p-2 px-3 mb-3 text-sm rounded-sm;
  }
</style>
