<template>
  <span class="spinner" :class="`${size} ${colorSchemeClasses}`" />
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
    colorScheme: {
      type: String,
      default: '',
    },
  },
  computed: {
    colorSchemeClasses() {
      if (this.colorScheme === 'primary') {
        return 'before:!border-t-accent';
      }

      if (this.colorScheme === 'warning') {
        return 'before:!border-t-warning';
      }

      if (this.colorScheme === 'success') {
        return 'before:!border-t-success';
      }

      return this.colorScheme;
    },
  },
};
</script>
<style scoped lang="scss">
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  @apply relative inline-block w-6 h-6 px-4 align-middle;
}

.spinner::before {
  content: '';
  @apply box-border absolute top-1/2 left-1/2 w-6 h-6 -mt-2.5 -ml-2.5 rounded-full border-2 border-primary-dark border-t-accent dark:border-secondary-dark-low dark:border-t-accent;
  animation: spinner 0.9s linear infinite;
}

.spinner.message {
  @apply p-2.5 top-0 left-0 mx-auto mt-3 bg-white rounded-[2rem];
}

.spinner.message::before {
  @apply -mt-3 -ml-3;
}

.spinner.small {
  @apply w-4 h-4;
}

.spinner.small::before {
  @apply w-4 h-4 -mt-2;
}

.spinner.tiny {
  @apply w-2.5 h-2.5 px-1;
}

.spinner.tiny::before {
  @apply w-2.5 h-2.5 -mt-1.5;
}
</style>
