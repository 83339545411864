<template>
  <svg
    v-if="iconLib === 'fluent'"
    :width="size"
    :height="size"
    fill="none"
    :viewBox="viewBox"
    :class="iconClass"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="(source, index) in pathSource"
      :key="source"
      :d="source"
      :stroke="strokeSource[index]"
      :fill="strokeSource[index] ? '' : fillSource[index] || 'currentColor'"
      :stroke-linecap="strokeLinecap"
      :stroke-linejoin="strokeLinejoin"
      :stroke-width="!isInSidebar ? '1.66667' : '1'"
    />
  </svg>
  <svg
    v-else
    :width="size"
    :height="size"
    fill="none"
    :viewBox="viewBox"
    :class="iconClass"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g v-for="(pathData, index) in pathSource" :key="index">
      <path :key="pathData" :d="pathData" stroke="currentColor" />
    </g>
  </svg>
</template>
<script>
import { mapGetters } from 'vuex';
import osDarkModeMixin from 'shared/mixins/osDarkModeMixin';

export default {
  mixins: [osDarkModeMixin],
  props: {
    icon: {
      type: String,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
    iconLib: {
      type: String,
      default: 'fluent',
    },
    forceLightColor: {
      type: Boolean,
      default: false,
    },
    isInSidebar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      colorScheme: 'colorScheme/getColorScheme',
    }),
    pathSource() {
      const pathKey = `${this.icon}-${this.type}`;
      const path = this.icons[pathKey];

      if (path && path.constructor === Object) {
        return path.paths;
      }

      if (path && path.constructor === Array) {
        return path;
      }
      if (path) {
        return [path];
      }
      return [];
    },
    fillSource() {
      const pathKey = `${this.icon}-${this.type}`;
      const path = this.icons[pathKey];

      if (path && path.constructor === Object) {
        return path.fills;
      }

      return [];
    },
    strokeSource() {
      const pathKey = `${this.icon}-${this.type}`;
      const path = this.icons[pathKey];

      if (path && path.constructor === Object) {
        return path.stroke;
      }

      return [];
    },
    strokeLinecap() {
      const pathKey = `${this.icon}-${this.type}`;
      const path = this.icons[pathKey];

      if (path && path.constructor === Object) {
        return path.strokelinecap || '';
      }
      return '';
    },
    strokeLinejoin() {
      const pathKey = `${this.icon}-${this.type}`;
      const path = this.icons[pathKey];

      if (path && path.constructor === Object) {
        return path.strokelinejoin || '';
      }
      return '';
    },
    isDarkMode() {
      return this.colorScheme === 'dark';
    },

    // since we add icon that has defined stroke color (eg: ai-instruction-outline) and cannot change icon color using text color,
    // we need to add custom class to handle the color for dark or light mode.
    iconClass() {
      let baseClass = [];

      // force icon color to light color (eg: in secondary sidebar)
      if (this.forceLightColor) {
        baseClass.push('light-color-only');
      }

      // add new dark class to modify icon color in dark mode
      // this class triggered by keyboard shortcut that use localstorage
      if (this.isDarkMode) {
        baseClass.push('dark');
      }

      // if the localstorage not exist, the we need to add custom class
      // this class triggered by Operating System theme switcher
      if (this.colorScheme === 'auto' && this.isOsOnDarkMode) {
        baseClass.push('dark-os');
      }

      return baseClass;
    },
  },
};
</script>

<style lang="scss" scoped>
// force icon color to light color (eg: in secondary sidebar)
.light-color-only {
  path {
    @apply stroke-primary-dark;
  }
}
</style>
