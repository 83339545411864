<template>
  <div
    v-on-clickaway="onCloseDropdown"
    class="response-draft"
    :class="{ 'pb-0': isKeyboardShortcutShowingUp }"
  >
    <div
      v-if="isFetchingIntents"
      class="h-full flex items-center justify-center"
    >
      <woot-loading-state :message="$t('SIDEBAR.AI_RESPONSE.FETCHING')" />
    </div>

    <template v-else>
      <div class="response-draft__head">
        <div
          class="response-draft__trigger"
          :class="{
            'response-draft__trigger--active': showSearchDropdown,
          }"
          @click="toggleDropdown"
        >
          <div class="response-draft__trigger-body">
            <div class="response-draft__trigger-icon">
              <Icon
                size="16"
                view-box="0 0 16 16"
                icon="memory"
                type="outline"
                :icons="iconLib"
              />
            </div>
            <div class="response-draft__trigger-selected">
              <h4
                v-if="!hasValue"
                class="mt-0 mb-0 mr-2 ml-0 text-ellipsis text-sm text-slate-800 dark:text-slate-100"
              >
                {{ $t('SIDEBAR.AI_RESPONSE.UNKNOWN') }}
              </h4>
              <h4
                v-else
                class="items-center leading-tight my-0 overflow-hidden whitespace-nowrap text-ellipsis text-sm text-slate-800 dark:text-slate-100"
                :title="selectedIntent.title"
              >
                {{ selectedIntent.title }}
              </h4>
            </div>
            <div class="response-draft__trigger-close">
              <fluent-icon
                v-if="showSearchDropdown"
                size="16"
                icon="chevron-up"
                type="outline"
              />
              <fluent-icon
                v-else
                size="16"
                icon="chevron-down"
                type="outline"
              />
            </div>
          </div>
        </div>
        <div
          :class="{ 'dropdown-pane--open': showSearchDropdown }"
          class="dropdown-pane"
        >
          <ai-intents-dropdown
            v-if="showSearchDropdown"
            :options="filteredOptions"
            :selected-items="[selectedIntent]"
            :input-placeholder="$t('SIDEBAR.AI_RESPONSE.FIND')"
            :is-loading="isSearching"
            :no-search-result="$t('SIDEBAR.AI_RESPONSE.NOT_FOUND')"
            @search="handleSearch"
            @action="handleActionButton"
            @click="onClickSelectItem"
          />
        </div>
      </div>
      <div class="response-draft__body">
        <div
          v-if="isFetchingGeneratedMessage"
          class="flex items-center justify-center h-full"
        >
          <woot-loading-state :message="$t('SIDEBAR.AI_RESPONSE.FETCHING')" />
        </div>
        <div
          v-else
          v-dompurify-html="generatedMessageContent"
          class="response-draft__content"
        />
      </div>
      <div class="response-draft__footer">
        <div class="response-draft__footer-item">
          <div
            v-tooltip.top="{
              content: $t('SIDEBAR.AI_RESPONSE.REWRITE_HINT'),
              classes: 'text-center',
            }"
            class="response-draft__footer-icon"
          >
            <key-icon icon-key="r" />
            <div class="response-draft__footer-icon-text" @click="handleRetry">
              {{ $t('SIDEBAR.AI_RESPONSE.REWRITE') }}
            </div>
          </div>
          <div
            v-tooltip.top="{
              content: $t('SIDEBAR.AI_RESPONSE.EDIT_HINT'),
              classes: 'text-center',
            }"
            class="response-draft__footer-icon"
          >
            <key-icon icon-key="i" />
            <div
              class="response-draft__footer-icon-text"
              @click="toggleModalInstruction"
            >
              {{ instructionButtonText }}
            </div>
          </div>
        </div>
        <div class="response-draft__footer-item">
          <div class="response-draft__footer-button">
            <woot-button
              v-tooltip.top="{
                content: $t('SIDEBAR.AI_RESPONSE.SKIP_HINT'),
                classes: 'text-center',
              }"
              color-scheme="secondary"
              size="small"
              @click="handleSkip"
            >
              {{ $t('SIDEBAR.AI_RESPONSE.SKIP') }} (S)
            </woot-button>
          </div>
          <div class="response-draft__footer-button">
            <woot-button
              v-tooltip.top="{
                content: $t('SIDEBAR.AI_RESPONSE.ADD_HINT'),
                classes: 'ai-response-tooltip',
              }"
              size="small"
              @click="handleApply"
            >
              {{ $t('SIDEBAR.AI_RESPONSE.ADD_DRAFT') }} (A)
            </woot-button>
          </div>
        </div>
      </div>
      <ai-modal-instruction
        :show="showModalInstruction"
        :intent="selectedIntent"
        :conversation-id="conversationId"
        :is-new-intent="isNewIntent"
        @cancel="toggleModalInstruction"
        @submit="handleSubmitInstruction"
      />
    </template>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapActions, mapGetters, mapState } from 'vuex';
import icons from 'shared/components/FluentIcon/icons.json';
import Icon from 'shared/components/FluentIcon/Icon.vue';
import KeyIcon from 'dashboard/components/KeyIcon';
import AiModalInstruction from './AiModalInstruction.vue';
import AiIntentsDropdown from './AiIntentsDropdown.vue';
import { debounce } from '@chatwoot/utils';
import AnalyticsHelper from 'dashboard/helper/AnalyticsHelper';
import { AI_EVENTS } from 'dashboard/helper/AnalyticsHelper/events';

export default {
  components: {
    Icon,
    KeyIcon,
    AiModalInstruction,
    AiIntentsDropdown,
  },
  mixins: [clickaway],
  inject: ['uiCommandBus'],
  props: {
    multiselectorTitle: {
      type: String,
      default: '',
    },
    conversationId: {
      type: Number,
      required: true,
    },
    isKeyboardShortcutShowingUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
      showModalInstruction: false,
      filteredOptions: [],
      searchQuery: '',
      isSearching: false,
      selectedIntent: null,
      isNewIntent: false,
    };
  },
  computed: {
    ...mapState('intents', ['records', 'uiFlags']),
    ...mapGetters('generatedMessage', [
      'getGeneratedMessage',
      'getGeneratedMessageUIFlags',
    ]),

    generatedMessageContent() {
      return this.getGeneratedMessage?.content || '';
    },
    isFetchingGeneratedMessage() {
      return this.getGeneratedMessageUIFlags.isFetchingGeneratedMessage;
    },
    hasValue() {
      return !!this.selectedIntent?.id;
    },
    iconLib() {
      return icons;
    },
    isFetchingIntents() {
      return this.uiFlags.isFetching;
    },
    instructionButtonText() {
      return this.hasValue
        ? this.$t('SIDEBAR.AI_RESPONSE.EDIT_INSTRUCTION')
        : this.$t('SIDEBAR.AI_RESPONSE.ADD_INSTRUCTION');
    },
  },
  watch: {
    records: {
      immediate: true,
      handler(newRecords) {
        this.filteredOptions = [...newRecords];
      },
    },
    showSearchDropdown(newValue) {
      if (!newValue) {
        this.resetFilteredOptions();
      }
    },
  },
  mounted() {
    this.uiCommandBus.$on('command', this.handleUiCommand);
  },

  created() {
    this.debouncedSearch = debounce(this.performSearch, 300);
    const initialize = async () => {
      await this.fetchIntents();
      await this.loadInitialGeneratedMessage();
    };

    initialize();
  },
  beforeMount() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
    this.uiCommandBus.$off('command', this.handleUiCommand);
  },
  methods: {
    ...mapActions('intents', ['get', 'getById']),
    ...mapActions('generatedMessage', ['fetchGeneratedMessage']),

    async loadInitialGeneratedMessage() {
      await this.fetchGeneratedMessage({
        conversationId: this.conversationId,
        intentId: this.selectedIntent?.id,
      });
      this.selectedIntent = this.records.find(
        record => record.id === this.getGeneratedMessage?.intent_id
      );
    },

    toggleDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
      if (this.showSearchDropdown) {
        this.resetFilteredOptions();
      }
    },
    onCloseDropdown() {
      this.showSearchDropdown = false;
      this.resetFilteredOptions();
    },
    resetFilteredOptions() {
      this.filteredOptions = [...this.records];
      this.searchQuery = '';
      this.isSearching = false;
    },
    onClickSelectItem(value) {
      if (this.selectedIntent !== value) {
        this.fetchGeneratedMessage({
          conversationId: this.conversationId,
          intentId: value.id,
        });
      }

      this.selectedIntent = value;
      this.$emit('click', value);
      this.onCloseDropdown();
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.showSearchDropdown = false;
      }
    },
    handleRetry() {
      AnalyticsHelper.track(AI_EVENTS.REWRITE, {
        conversationId: this.conversationId,
        intentId: this.selectedIntent?.id,
      });

      this.fetchGeneratedMessage({
        conversationId: this.conversationId,
        regenerate: true,
        intentId: this.selectedIntent?.id,
      });
    },
    handleSkip() {
      this.$emit('skip', this.conversationId, this.selectedIntent?.id);
    },
    handleApply() {
      const value = {
        description: this.generatedMessageContent || '',
        enabled: this.selectedIntent?.enabled ?? false,
        id: this.selectedIntent?.id,
        instructions: this.selectedIntent?.instructions || '',
        title: this.selectedIntent?.title || '',
        trigger_count: this.selectedIntent?.trigger_count ?? 0,
      };
      this.$emit(
        'apply',
        value.description,
        this.conversationId,
        this.selectedIntent?.id
      );
    },
    handleUiCommand(command) {
      if (command === 'ai-retry') {
        this.handleRetry();
      } else if (command === 'ai-add-instruction') {
        this.toggleModalInstruction();
      } else if (command === 'ai-skip') {
        this.handleSkip();
      } else if (command === 'ai-apply') {
        this.handleApply();
      }
    },
    toggleModalInstruction() {
      if (this.showModalInstruction) {
        // we will be hiding the modal
        this.showModalInstruction = false;
        this.showSearchDropdown = false;
      } else {
        // we will be showing the modal
        // so this is a new intent if there is no selected intent
        this.isNewIntent = !this.hasValue;
        this.showModalInstruction = true;
        this.showSearchDropdown = false;
      }
    },
    handleSubmitInstruction(val) {
      this.toggleModalInstruction();
      this.handleRetry();
      // assign new intent or updated intent to selected dropdown
      this.selectedIntent = val;
    },
    handleSearch(query) {
      this.searchQuery = query;
      this.isSearching = true;
      this.debouncedSearch();
    },

    async fetchIntents() {
      await this.get();
    },

    async performSearch() {
      this.isSearching = true;
      try {
        if (!this.searchQuery) {
          this.resetFilteredOptions();
        } else {
          this.filteredOptions = this.records.filter(
            option =>
              option.title
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              option.description
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
          );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error during search:', error);
      } finally {
        this.isSearching = false;
      }
    },

    // action button for create new intent
    handleActionButton() {
      this.isNewIntent = true;
      this.showModalInstruction = true;
      this.showSearchDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.response-draft {
  @apply relative pt-4 h-full;

  .dropdown-pane {
    @apply w-[calc(100%-1.05rem)] top-[4rem] h-[80%] rounded-[0.3125rem] border border-neutral-medium dark:border-secondary-dark-low;
  }

  &__trigger {
    @apply w-full px-2.5;

    &-body {
      @apply flex items-center relative flex-auto min-w-0;
    }

    &-icon {
      @apply w-4 text-[#5647FF] dark:text-[#9389fe] relative top-[2px] mr-2;
    }

    &-selected {
      @apply w-full pr-12;
    }

    &-close {
      @apply absolute top-1/2 right-0 transform -translate-y-1/2 text-center text-primary dark:text-primary-dark;
    }
  }

  &__body {
    @apply py-4 mt-4 border-t border-neutral-high dark:border-neutral-dark-medium h-[calc(100%-65px)] overflow-y-auto;
  }

  &__content {
    @apply border-0 text-sm leading-6;
    overflow-wrap: anywhere;
  }

  &__footer {
    @apply text-sm flex items-center bg-white dark:bg-primary py-4 border-t border-neutral-high dark:border-neutral-dark-medium sticky bottom-0 left-0;

    &-item {
      @apply text-sm flex items-center w-[54%];

      &:last-child {
        @apply justify-end w-[46%];
      }
    }

    &-icon {
      @apply flex items-center mr-4;

      &:last-child {
        @apply mr-0;
      }
    }

    &-icon-text {
      @apply text-[12px] leading-3 md:leading-normal lg:text-xs transition-all duration-200 ease-in select-none;

      &:hover {
        @apply cursor-pointer text-accent dark:text-accent-dark;
      }
    }

    &-button {
      @apply mr-1.5;

      &:last-child {
        @apply mr-0;
      }
    }
  }
}

// reset button trigger
.response-draft__trigger {
  @apply border
  border-neutral-high
  dark:border-neutral-dark-medium
  transition-none
  hover:bg-transparent
  dark:hover:bg-transparent
  hover:border-neutral-medium
  dark:hover:border-secondary-dark-low
  rounded-[0.3125rem]
  h-[40px]
  cursor-pointer
  flex
  items-center;

  // active button trigger
  &--active {
    @apply bg-white dark:bg-[#26292b] dark:border-secondary-dark-low border-neutral-medium hover:bg-white dark:hover:bg-[#26292b];
  }
}
</style>
