<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      :href="href"
      class="nav-item"
      :class="{
        'nav-item-active': isActive || isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <Icon
        class="nav-item-icon"
        :class="{
          'nav-item-icon-active': isActive || isChildMenuActive,
        }"
        size="19"
        :view-box="icon === 'ai-sparkle' ? '-1 -1 22 22' : '0 0 16 16'"
        :icon="icon"
        type="outline"
        :icons="iconLib"
        is-in-sidebar
      />
      <span>{{ $t(`SIDEBAR.${name}`) }}</span>
      <span v-if="count" class="count-badge">
        {{ count }}
      </span>
    </a>
  </router-link>
</template>
<script>
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';

export default {
  components: {
    Icon,
  },
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  },
  computed: {
    iconLib() {
      return icons;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  @apply text-primary
  dark:text-primary-dark
  flex
  items-center
  rounded-[4px]
  leading-4
  m-0
  mt-1
  text-sm
  h-[33px]
  p-2
  hover:bg-neutral-low
  dark:hover:bg-primary
  relative
  w-full;
}
.nav-item-icon {
  @apply mr-2;
}
.nav-item-active {
  @apply bg-neutral-low dark:bg-primary;
}
.count-badge {
  @apply rounded-sm text-xxs mx-1 py-0 px-1 text-accent dark:text-accent-dark bg-accent-low dark:bg-accent-dark-low;
}
.nav-item-icon-active {
  @apply text-primary dark:text-primary-dark;
}
</style>
