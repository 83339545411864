var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":"","active-class":"active"},nativeOn:{"click":function($event){return _vm.handleRouteClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('li',{staticClass:"submenu-item",class:[
      _vm.modifierClass,
      {
        'submenu-item-active': isActive,
        'submenu-item-truncated': _vm.shouldTruncate,
      },
    ],on:{"click":navigate}},[_c('a',{staticClass:"submenu-item-link",attrs:{"href":href}},[(_vm.icon && !_vm.isInsideDropdown)?_c('span',{staticClass:"submenu-item-inner"},[_c('fluent-icon',{staticClass:"submenu-item-icon",class:{
            'submenu-item-icon-active': isActive,
          },attrs:{"icon":_vm.icon,"size":17,"viewBox":_vm.isMessengerIcon ? '-4 -4 24 24' : '0 0 24 24'}})],1):_vm._e(),_vm._v(" "),(_vm.labelColor)?_c('span',{staticClass:"submenu-item-label",style:({ backgroundColor: _vm.labelColor })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"},[_c('span',{staticClass:"submenu-item-text",class:{
            'submenu-item-text-active': isActive,
            'submenu-item-text-truncated': _vm.shouldTruncate,
          },attrs:{"title":_vm.menuTitle}},[_vm._v("\n          "+_vm._s(_vm.label)+"\n        ")]),_vm._v(" "),(_vm.showChildCount)?_c('span',{staticClass:"rounded-sm text-xxs mx-1 py-0 px-1 text-accent dark:text-accent-dark bg-accent-low dark:bg-accent-dark-low",class:_vm.isCountZero
              ? `text-accent dark:text-accent-dark`
              : `text-accent dark:text-accent-dark`},[_vm._v("\n          "+_vm._s(_vm.childItemCount)+"\n        ")]):_vm._e()]),_vm._v(" "),(_vm.warningIcon)?_c('span',{staticClass:"inline-flex rounded-sm mr-1 p-[1px] bg-warning-subtle dark:bg-warning-dark-faint"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('SIDEBAR.REAUTHORIZE')),expression:"$t('SIDEBAR.REAUTHORIZE')",modifiers:{"top-end":true}}],staticClass:"text-xxs text-warning",attrs:{"icon":_vm.warningIcon,"size":"12"}})],1):_vm._e()])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }