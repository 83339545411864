<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('SHOP_MGMT.ADD.TITLE')"
      :header-content="$t('SHOP_MGMT.ADD.DESC')"
    />
    <form class="w-full" @submit.prevent="addShop">
      <div class="w-full">
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button class="button primary gap-2" data-testid="label-submit">
            {{ $t('SHOP_MGMT.FORM.CREATE') }}
          </woot-button>
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('SHOP_MGMT.FORM.CANCEL') }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import validationMixin from './validationMixin';
import { mapGetters } from 'vuex';
import validations from './validations';

export default {
  mixins: [alertMixin, validationMixin],
  props: {
    prefillDomain: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      shopDomain: '',
    };
  },
  validations,
  computed: {
    ...mapGetters({
      uiFlags: 'shops/getUIFlags',
    }),
  },
  mounted() {
    this.title = this.prefillDomain.toLowerCase();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async addShop() {
      try {
        window.location = `https://apps.shopify.com/commslayer`;
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('SHOP_MGMT.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// Label API supports only lowercase letters
.label-name--input {
  ::v-deep {
    input {
      @apply lowercase;
    }
  }
}
</style>
