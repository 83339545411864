<template>
  <div
    v-if="!isInNotificationPage"
    v-tooltip.right="tooltipOptions"
    class="notification"
    :class="{
      'justify-center relative': !isSidebarExpanded,
    }"
    @click="openNotificationPanel"
  >
    <div v-if="unreadCount && !isSidebarExpanded" class="counter-collapsed">
      {{ unreadCount }}
    </div>
    <div
      class="notification__prefix"
      :class="{ 'mr-2 rtl:mr-0 rtl:ml-2': isSidebarExpanded }"
    >
      <Icon
        size="19"
        view-box="0.9 0.5 15 15"
        icon="bell"
        type="outline"
        :icons="iconLib"
        is-in-sidebar
      />
    </div>
    <template v-if="isSidebarExpanded">
      <div class="notification__text">Notifications</div>
      <div v-if="unreadCount" class="notification__suffix">
        <div class="counter">
          {{ unreadCount }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';

export default {
  components: {
    Icon,
  },
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return '';
      }

      return this.notificationMetadata.unreadCount < 100
        ? `${this.notificationMetadata.unreadCount}`
        : '99+';
    },
    isInNotificationPage() {
      return this.$route.name === 'notifications_index';
    },
    tooltipOptions() {
      if (this.isSidebarExpanded) {
        return { content: '', classes: '' };
      }
      return {
        content: 'Notifications',
        classes: 'new-sidebar-tooltip',
      };
    },
    iconLib() {
      return icons;
    },
  },
  methods: {
    openNotificationPanel() {
      if (this.$route.name !== 'notifications_index') {
        this.$emit('open-notification-panel');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  p-2
  m-0
  h-[33px]
  text-sm
  text-secondary
  dark:text-secondary
  hover:text-secondary
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  border
  border-transparent
  cursor-pointer
  mt-6;

  &__prefix {
    @apply w-[18px] h-[19px] relative;
  }
  &__text {
    @apply leading-none w-[80%];
  }
  &__suffix {
    @apply flex items-center justify-end w-[16px] h-[16px] relative ml-2 rtl:mr-0 rtl:ml-2;
  }
}

.counter,
.counter-collapsed {
  @apply rounded-sm text-xxs mx-1 text-accent dark:text-accent-dark bg-accent-low dark:bg-accent-dark-low leading-none;
}

.counter-collapsed {
  @apply py-0.5 px-0.5 z-10 m-0;
  position: absolute;
  top: 30%;
  left: 75%;
  transform: translate(-50%, -50%);
}
</style>
