<template>
  <div
    class="training-bubble"
    :class="{
      left: alignBubble.left,
      right: alignBubble.right,
    }"
  >
    <div class="training-bubble__wrap">
      <!-- thumbnail -->
      <div class="training-bubble__head">
        <woot-thumbnail :src="thumbnail" :username="name" size="24px" />
      </div>
      <div class="training-bubble__body">
        <!-- label -->
        <div v-if="label" class="training-bubble__label">
          <div class="training-bubble__label-wrap">
            <div class="training-bubble__label-icon">
              <Icon
                size="16"
                view-box="0 0 16 16"
                icon="memory"
                type="outline"
                :icons="iconLib"
              />
            </div>
            <div class="training-bubble__label-text">
              {{ label }}
            </div>
          </div>
        </div>

        <!-- content -->
        <div class="training-bubble__content">
          <div v-dompurify-html="formattedContent" />
          <div class="training-bubble__time">{{ readableTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeMixin from 'dashboard/mixins/time';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import icons from 'shared/components/FluentIcon/icons.json';
import Icon from 'shared/components/FluentIcon/Icon.vue';

export default {
  components: {
    Icon,
  },
  mixins: [timeMixin],
  props: {
    content: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    createdAt: {
      type: Number,
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconLib() {
      return icons;
    },
    formattedContent() {
      let formatted = this.content.replace(/\n\n/g, '</p><p>');
      formatted = formatted.replace(/\n/g, '<br>');
      return `<p>${formatted}</p>`;
    },
    readableTime() {
      return this.messageTimestamp(this.createdAt, 'LLL d, h:mm a');
    },
    alignBubble() {
      const isLeftAligned = this.type === MESSAGE_TYPE.INCOMING;
      const isRightAligned =
        this.type === MESSAGE_TYPE.OUTGOING ||
        this.type === MESSAGE_TYPE.TEMPLATE;
      return {
        left: isLeftAligned,
        right: isRightAligned,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.training-bubble {
  @apply flex items-center;

  &__wrap {
    @apply mb-6 flex items-end px-7;
    max-width: min(50rem, 84%);
  }

  &__head {
    @apply mr-3;
  }

  &__label {
    @apply flex items-center justify-end;

    &-wrap {
      @apply flex items-center justify-center bg-white dark:bg-primary mb-2 p-1 px-2 rounded-md shadow-thin;
    }

    &-text {
      @apply text-xs text-primary dark:text-primary-dark;
    }

    &-icon {
      @apply mr-0.5 w-4 text-[#5647FF] dark:text-[#9389fe] relative top-[2px];
    }
  }

  &__content {
    @apply shadow-thin border border-neutral-medium dark:border-neutral-dark-medium bg-neutral dark:bg-primary text-primary dark:text-primary-dark rounded-lg rounded-bl-sm mr-auto p-4 text-sm;
    overflow-wrap: anywhere;
  }

  &__time {
    @apply mt-6 text-secondary dark:text-secondary-dark text-xs;
  }

  &.left {
    @apply justify-start;
  }

  &.right {
    @apply justify-end;

    .training-bubble__wrap {
      @apply flex-row-reverse;
    }

    .training-bubble__head {
      @apply mr-0 ml-3;
    }

    .training-bubble__content {
      @apply rounded-br-sm rounded-bl-lg bg-tertiary-medium dark:bg-tertiary-dark-medium text-white dark:text-white;
    }

    .training-bubble__time {
      @apply text-tertiary-low dark:text-tertiary-low;
    }
  }
}
</style>
