<template>
  <div class="empty-state py-16 px-1 ml-0 mr-0">
    <div
      v-if="title"
      class="text-secondary dark:text-secondary-dark block text-center w-full text-xl font-light"
    >
      {{ title }}
    </div>
    <p
      v-if="message"
      class="block text-center text-secondary dark:text-secondary-dark my-4 mx-auto w-[90%]"
    >
      {{ message }}
    </p>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
  },
};
</script>
