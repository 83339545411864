var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editorRoot",staticClass:"relative editor-root",class:{
    '!max-h-full': _vm.channelType === 'Channel::Email' && _vm.isPrivate,
  },style:({
    maxHeight: `calc(100% - ${
      _vm.channelType === 'Channel::Email' && !_vm.isPrivate
        ? _vm.replyEmailHeadHeight + 7
        : undefined
    }px)`,
  })},[(_vm.showUserMentions && _vm.isPrivate)?_c('tag-agents',{attrs:{"search-key":_vm.mentionSearchKey},on:{"click":_vm.insertMentionNode}}):_vm._e(),_vm._v(" "),(_vm.shouldShowCannedResponses)?_c('canned-response',{attrs:{"search-key":_vm.cannedSearchTerm},on:{"click":_vm.insertCannedResponse}}):_vm._e(),_vm._v(" "),(_vm.shouldShowVariables)?_c('variable-list',{attrs:{"search-key":_vm.variableSearchTerm},on:{"click":_vm.insertVariable}}):_vm._e(),_vm._v(" "),_c('input',{ref:"imageUpload",attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg, image/gif, image/webp","hidden":""},on:{"change":_vm.onFileChange}}),_vm._v(" "),_c('div',{staticClass:"max-h-full overflow-y-auto pr-4"},[_c('div',{ref:"editor"})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isImageNodeSelected && _vm.showImageResizeToolbar),expression:"isImageNodeSelected && showImageResizeToolbar"}],staticClass:"absolute shadow-md rounded-[4px] flex gap-1 py-1 px-1 bg-slate-50 dark:bg-slate-700 text-slate-800 dark:text-slate-50",style:({
      top: _vm.toolbarPosition.top,
      left: _vm.toolbarPosition.left,
    })},_vm._l((_vm.sizes),function(size){return _c('button',{key:size.name,staticClass:"text-xs font-medium rounded-[4px] border border-solid border-slate-200 dark:border-slate-600 px-1.5 py-0.5 hover:bg-slate-100 dark:hover:bg-slate-800",on:{"click":function($event){return _vm.setURLWithQueryAndImageSize(size)}}},[_vm._v("\n      "+_vm._s(size.name)+"\n    ")])}),0),_vm._v(" "),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }