import posthog from 'posthog-js';
import SettingsContent from '../Wrapper';
import AttributesHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/custom-attributes'),
      component: SettingsContent,
      props: {
        headerTitle: 'ATTRIBUTES_MGMT.HEADER',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'attributes_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'attributes_list',
          component: AttributesHome,
          roles: ['administrator'],
          beforeEnter: (to, from, next) => {
            // get account id from params
            const accountId = to.params.accountId;

            // if featureflag disabled -> redirect to homepage (dashboard)
            if (posthog.isFeatureEnabled('show-custom-attributes')) {
              next();
            } else {
              next({ name: 'home', params: { accountId } });
            }
          },
        },
      ],
    },
  ],
};
