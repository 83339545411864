<template>
  <div class="flex-1 overflow-auto p-4">
    <!-- List Canned Response -->
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <p
          v-if="!inboxesList.length"
          class="flex h-full items-center flex-col justify-center"
        >
          {{ $t('INBOX_MGMT.LIST.404') }}
          <router-link
            v-if="isAdmin"
            :to="addAccountScoping('settings/inboxes/new')"
          >
            {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
          </router-link>
        </p>

        <table v-if="inboxesList.length" class="woot-table">
          <tbody>
            <tr v-for="item in inboxesList" :key="item.id">
              <td>
                <img
                  v-if="item.avatar_url"
                  class="woot-thumbnail"
                  :src="item.avatar_url"
                  alt="No Page Image"
                />
                <img
                  v-else
                  class="woot-thumbnail"
                  src="~dashboard/assets/images/flag.svg"
                  alt="No Page Image"
                />
              </td>
              <!-- Short Code  -->
              <td>
                <span class="inbox-name">{{ item.name }}</span>
                <span v-if="item.channel_type === 'Channel::FacebookPage'">
                  Facebook
                </span>
                <span
                  v-if="
                    item.channel_type === 'Channel::WebWidget' &&
                    item.shopify_widget
                  "
                >
                  Shopify
                </span>
                <span
                  v-if="
                    item.channel_type === 'Channel::WebWidget' &&
                    !item.shopify_widget
                  "
                >
                  Website
                </span>
                <span v-if="item.channel_type === 'Channel::TwitterProfile'">
                  Twitter
                </span>
                <span v-if="item.channel_type === 'Channel::TwilioSms'">
                  {{ twilioChannelName(item) }}
                </span>
                <span v-if="item.channel_type === 'Channel::Whatsapp'">
                  Whatsapp
                </span>
                <span v-if="item.channel_type === 'Channel::Sms'"> Sms </span>
                <span v-if="item.channel_type === 'Channel::Email'">
                  Email
                </span>
                <span v-if="item.channel_type === 'Channel::Telegram'">
                  Telegram
                </span>
                <span v-if="item.channel_type === 'Channel::Line'">Line</span>
                <span v-if="item.channel_type === 'Channel::Api'">
                  {{ globalConfig.apiChannelName || 'API' }}
                </span>
                <span v-if="item.channel_type === 'Channel::Imported'">
                  Imported
                </span>
              </td>

              <td>
                <!-- START Action Button -->
                <!-- If use email provider (have provider_config) -->
                <template v-if="item.provider_config">
                  <template
                    v-if="
                      validateObjectProp(
                        item.provider_config,
                        'dns_process_step'
                      )
                    "
                  >
                    <!-- SIMPLE process -->
                    <template
                      v-if="
                        item.provider_config.dns_process_step.flow_type ===
                        'simple'
                      "
                    >
                      <template
                        v-if="
                          validateObjectProp(
                            item.provider_config,
                            'postmark_sender'
                          )
                        "
                      >
                        <!-- If email confirmed -->
                        <template
                          v-if="item.provider_config.postmark_sender.confirmed"
                        >
                          <router-link
                            :to="
                              addAccountScoping(`settings/inboxes/${item.id}`)
                            "
                          >
                            <woot-button
                              v-if="isAdmin"
                              v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                              variant="smooth"
                              size="tiny"
                              icon="settings"
                              color-scheme="secondary"
                              class-names="grey-btn"
                            />
                          </router-link>
                        </template>

                        <!-- If email NOT confirmed -->
                        <template v-else>
                          <div class="mb-2">
                            <capsule variant="tertiary" text="Unfinished">
                              <template #icon>
                                <fluent-icon
                                  icon="warning"
                                  type="solid"
                                  size="14"
                                  view-box="0 0 16 16"
                                />
                              </template>
                            </capsule>
                          </div>
                          <router-link
                            :to="
                              addAccountScoping(
                                `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                              )
                            "
                          >
                            <woot-button
                              v-if="isAdmin"
                              v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                              variant="smooth"
                              size="tiny"
                              icon="settings"
                              color-scheme="secondary"
                              class-names="grey-btn"
                            />
                          </router-link>
                        </template>
                      </template>

                      <!-- If email confirmed button not triggered -->
                      <template v-else>
                        <div class="mb-2 mt-1">
                          <capsule variant="tertiary" text="Unfinished">
                            <template #icon>
                              <fluent-icon
                                icon="warning"
                                type="solid"
                                size="14"
                                view-box="0 0 16 16"
                              />
                            </template>
                          </capsule>
                        </div>
                        <router-link
                          :to="
                            addAccountScoping(
                              `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                            )
                          "
                        >
                          <woot-button
                            v-if="isAdmin"
                            v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                            variant="smooth"
                            size="tiny"
                            icon="settings"
                            color-scheme="secondary"
                            class-names="grey-btn"
                          />
                        </router-link>
                      </template>
                    </template>

                    <!-- ADVANCE process -->
                    <template v-else>
                      <template
                        v-if="
                          validateObjectProp(
                            item.provider_config,
                            'postmark_sender'
                          )
                        "
                      >
                        <!-- If DNS & Email verified -->
                        <template
                          v-if="
                            item.provider_config.postmark_sender.confirmed &&
                            item.provider_config.postmark_domain
                              .dkim_verified &&
                            item.provider_config.postmark_domain
                              .return_path_verified
                          "
                        >
                          <router-link
                            :to="
                              addAccountScoping(`settings/inboxes/${item.id}`)
                            "
                          >
                            <woot-button
                              v-if="isAdmin"
                              v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                              variant="smooth"
                              size="tiny"
                              icon="settings"
                              color-scheme="secondary"
                              class-names="grey-btn"
                            />
                          </router-link>
                        </template>
                        <!-- If DNS NOT verified -->
                        <template v-else-if="
                            !item.provider_config.postmark_domain
                              .dkim_verified ||
                            !item.provider_config.postmark_domain
                              .return_path_verified
                          ">
                          <div class="mb-2">
                            <capsule variant="tertiary" text="Unfinished">
                              <template #icon>
                                <fluent-icon
                                  icon="warning"
                                  type="solid"
                                  size="14"
                                  view-box="0 0 16 16"
                                />
                              </template>
                            </capsule>
                          </div>
                          <router-link
                            :to="
                              addAccountScoping(
                                `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=2&flow_type=${item.provider_config.dns_process_step.flow_type}`
                              )
                            "
                          >
                            <woot-button
                              v-if="isAdmin"
                              v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                              variant="smooth"
                              size="tiny"
                              icon="settings"
                              color-scheme="secondary"
                              class-names="grey-btn"
                            />
                          </router-link>
                        </template>
                        <!-- If Email NOT verified -->
                        <template v-else-if="!item.provider_config.postmark_sender.confirmed">
                          <div class="mb-2">
                            <capsule variant="tertiary" text="Unfinished">
                              <template #icon>
                                <fluent-icon
                                  icon="warning"
                                  type="solid"
                                  size="14"
                                  view-box="0 0 16 16"
                                />
                              </template>
                            </capsule>
                          </div>
                          <router-link
                            :to="
                              addAccountScoping(
                                `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=3&flow_type=${item.provider_config.dns_process_step.flow_type}`
                              )
                            "
                          >
                            <woot-button
                              v-if="isAdmin"
                              v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                              variant="smooth"
                              size="tiny"
                              icon="settings"
                              color-scheme="secondary"
                              class-names="grey-btn"
                            />
                          </router-link>
                        </template>
                        <template v-else>
                          <div class="mb-2">
                            <capsule variant="tertiary" text="Unfinished">
                              <template #icon>
                                <fluent-icon
                                  icon="warning"
                                  type="solid"
                                  size="14"
                                  view-box="0 0 16 16"
                                />
                              </template>
                            </capsule>
                          </div>
                          <router-link
                            :to="
                              addAccountScoping(
                                `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                              )
                            "
                          >
                            <woot-button
                              v-if="isAdmin"
                              v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                              variant="smooth"
                              size="tiny"
                              icon="settings"
                              color-scheme="secondary"
                              class-names="grey-btn"
                            />
                          </router-link>
                        </template>
                      </template>
                      <!-- If email confirmed button not triggered -->
                      <template v-else>
                        <div class="mb-2 mt-1">
                          <capsule variant="tertiary" text="Unfinished">
                            <template #icon>
                              <fluent-icon
                                icon="warning"
                                type="solid"
                                size="14"
                                view-box="0 0 16 16"
                              />
                            </template>
                          </capsule>
                        </div>
                        <router-link
                          :to="
                            addAccountScoping(
                              `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                            )
                          "
                        >
                          <woot-button
                            v-if="isAdmin"
                            v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                            variant="smooth"
                            size="tiny"
                            icon="settings"
                            color-scheme="secondary"
                            class-names="grey-btn"
                          />
                        </router-link>
                      </template>
                    </template>
                  </template>
                </template>

                <!-- If NOT email provider (doesn't have provider_config) -->
                <template v-else>
                  <router-link
                    :to="addAccountScoping(`settings/inboxes/${item.id}`)"
                  >
                    <woot-button
                      v-if="isAdmin"
                      v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                      variant="smooth"
                      size="tiny"
                      icon="settings"
                      color-scheme="secondary"
                      class-names="grey-btn"
                    />
                  </router-link>
                </template>

                <woot-button
                  v-if="isAdmin"
                  v-tooltip.top="$t('INBOX_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  class-names="grey-btn"
                  :is-loading="loading[item.id]"
                  icon="dismiss-circle"
                  @click="openDelete(item)"
                />
              </td>
              <!-- END Action Button -->
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-[34%]">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('INBOX_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <settings
      v-if="showSettings"
      :show.sync="showSettings"
      :on-close="closeSettings"
      :inbox="selectedInbox"
    />

    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
      :message="confirmDeleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedInbox.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Settings from './Settings.vue';
import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Capsule from '../../settings/inbox/components/Capsule.vue';

export default {
  components: {
    Settings,
    Capsule,
  },
  mixins: [adminMixin, accountMixin, globalConfigMixin],
  data() {
    return {
      loading: {},
      showSettings: false,
      showDeletePopup: false,
      selectedInbox: {},
    };
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
      globalConfig: 'globalConfig/get',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedInbox.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedInbox.name
      }`;
    },
    confirmDeleteMessage() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedInbox.name
      }?`;
    },
    confirmPlaceHolderText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        inboxName: this.selectedInbox.name,
      })}`;
    },
  },
  methods: {
    validateObjectProp(obj, prop) {
      return Object.hasOwn(obj, prop);
    },
    twilioChannelName(item) {
      const { medium = '' } = item;
      if (medium === 'whatsapp') return 'WhatsApp';
      return 'Twilio SMS';
    },
    openSettings(inbox) {
      this.showSettings = true;
      this.selectedInbox = inbox;
    },
    closeSettings() {
      this.showSettings = false;
      this.selectedInbox = {};
    },
    async deleteInbox({ id }) {
      try {
        await this.$store.dispatch('inboxes/delete', id);
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },

    confirmDeletion() {
      this.deleteInbox(this.selectedInbox);
      this.closeDelete();
    },
    openDelete(inbox) {
      this.showDeletePopup = true;
      this.selectedInbox = inbox;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedInbox = {};
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tbody {
    td {
      @apply pb-3;
    }
  }
}
</style>
