<template>
  <div
    v-if="showHead"
    class="message__mail-head"
    :class="{
      'is-outgoing': isOutgoing,
      'is-failed': isFailed,
    }"
  >
    <table class="mail-table">
      <tr v-if="fromMail">
        <td width="15">{{ $t('EMAIL_HEADER.FROM') }}</td>
        <td class="break-all">{{ fromMail }}</td>
      </tr>
      <tr v-if="toMails">
        <td width="15">{{ $t('EMAIL_HEADER.TO') }}</td>
        <td class="break-all">{{ toMails }}</td>
      </tr>
      <tr v-if="ccMails">
        <td width="15">{{ $t('EMAIL_HEADER.CC') }}</td>
        <td class="break-all">{{ ccMails }}</td>
      </tr>
      <tr v-if="bccMails">
        <td width="15">{{ $t('EMAIL_HEADER.BCC') }}</td>
        <td class="break-all">{{ bccMails }}</td>
      </tr>
      <tr v-if="subject">
        <td width="15">{{ $t('EMAIL_HEADER.SUBJECT') }}</td>
        <td class="break-all">{{ subject }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    emailAttributes: {
      type: Object,
      default: () => ({}),
    },
    isOutgoing: {
      type: Boolean,
      default: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    cc: {
      type: Array,
      default: () => [],
    },
    bcc: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fromMail() {
      const from = this.emailAttributes.from || [];
      return from.join(', ');
    },
    toMails() {
      const to = this.emailAttributes.to || [];
      return to.join(', ');
    },
    ccMails() {
      const cc = this.emailAttributes.cc || this.cc || [];
      return cc.join(', ');
    },
    bccMails() {
      const bcc = this.emailAttributes.bcc || this.bcc || [];
      return bcc.join(', ');
    },
    subject() {
      return this.emailAttributes.subject || '';
    },
    showHead() {
      return this.toMails || this.ccMails || this.bccMails || this.fromMail;
    },
  },
};
</script>

<style lang="scss" scoped>
.message__mail-head {
  @apply text-secondary dark:text-secondary-dark border-b border-neutral-medium dark:border-neutral-dark-medium pb-2 mb-3;

  &.is-outgoing {
    @apply border-secondary dark:border-secondary border-opacity-30 dark:border-opacity-30;

    .mail-table td {
      @apply text-secondary dark:text-secondary-dark;
    }
  }

  &.is-outgoing.is-failed {
    @apply border-secondary dark:border-primary-dark border-opacity-30 dark:border-opacity-30;

    .mail-table td {
      @apply text-secondary dark:text-primary-dark;
    }
  }
}

.meta-wrap {
  .message__content--type {
    @apply text-xs text-secondary dark:text-secondary-dark font-semibold;
  }
  span {
    @apply text-xs;
  }
}

.mail-table {
  @apply p-0 mb-0 w-full break-words;

  tr {
    td:first-child {
      @apply font-semibold pr-2.5;
    }
  }

  tr:last-child {
    td {
      @apply pb-0;
    }
  }

  td {
    @apply pb-1 align-top text-xs text-secondary dark:text-secondary-dark;
  }
}
</style>
