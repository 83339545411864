<template>
  <div
    class="flex flex-grow w-full min-w-0 pl-0 pr-0 overflow-auto bg-neutral settings dark:bg-primary"
  >
    <div class="grid grid-cols-10 flex-1">
      <div class="md:col-span-6 col-span-10 bg-tertiary-subtle dark:bg-primary">
        <div class="pt-5 pb-10 pl-8 pr-8">
          <facebook-reauthorize v-if="facebookUnauthorized" :inbox="inbox" />
          <gmail-reauthorize v-if="gmailUnauthorized" :inbox="inbox" />
          <aircall-reauthorize v-if="aircallUnauthorized" :inbox="inbox" />
          <div class="mb-3">
            <ExpandableBox
              :heading="$t('CHAT_WIDGET_SETTINGS.COPYWRITING')"
              :subheading="$t('CHAT_WIDGET_SETTINGS.COPYWRITING_SUBHEADING')"
            >
              <woot-input
                v-model.trim="selectedInboxName"
                class="w-full pb-4"
                :class="{ error: $v.selectedInboxName.$error }"
                :label="inboxNameLabel"
                :placeholder="inboxNamePlaceHolder"
                :error="
                  $v.selectedInboxName.$error
                    ? $t('INBOX_MGMT.ADD.CHANNEL_NAME.ERROR')
                    : ''
                "
                @blur="$v.selectedInboxName.$touch"
              />

              <woot-input
                v-if="isAWebWidgetInbox"
                v-model.trim="channelWelcomeTitle"
                class="w-full pb-4"
                :label="
                  $t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL'
                  )
                "
                :placeholder="
                  $t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER'
                  )
                "
              />

              <woot-input
                v-if="isAWebWidgetInbox"
                v-model.trim="channelWelcomeTagline"
                class="w-full pb-4"
                :label="
                  $t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL'
                  )
                "
                :placeholder="
                  $t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
                  )
                "
              />

              <label
                v-if="isAWebWidgetInbox || isAnEmailChannel || isAnGmailChannel"
              >
                <div>{{ $t('INBOX_MGMT.EDIT.SENDER_NAME_SECTION.TITLE') }}</div>
                <p>{{ $t('INBOX_MGMT.EDIT.SENDER_NAME_SECTION.SUB_TEXT') }}</p>
              </label>

              <div
                v-if="isAWebWidgetInbox || isAnEmailChannel || isAnGmailChannel"
                class="w-full pb-4"
              >
                <sender-name-example-preview
                  :sender-name-type="senderNameType"
                  :business-name="businessName"
                  @update="toggleSenderNameType"
                />
                <div class="flex flex-col items-stretch gap-2 mt-2">
                  <woot-button
                    variant="clear"
                    color-scheme="primary"
                    @click="onClickShowBusinessNameInput"
                  >
                    {{
                      $t(
                        'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.BUTTON_TEXT'
                      )
                    }}
                  </woot-button>
                  <div v-if="showBusinessNameInput" class="flex gap-2 w-[80%]">
                    <input
                      ref="businessNameInput"
                      v-model="businessName"
                      :placeholder="
                        $t(
                          'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.PLACEHOLDER'
                        )
                      "
                      class="mb-0"
                      type="text"
                    />
                    <woot-button color-scheme="primary" @click="updateInbox">
                      {{
                        $t(
                          'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.SAVE_BUTTON_TEXT'
                        )
                      }}
                    </woot-button>
                  </div>
                </div>
              </div>

              <div>
                <woot-button color-scheme="primary" @click="updateInbox">
                  {{
                    $t(
                      'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.SAVE_BUTTON_TEXT'
                    )
                  }}
                </woot-button>
              </div>
            </ExpandableBox>
          </div>

          <template v-if="isAWebWidgetInbox">
            <ExpandableBox
              class="mb-3"
              :heading="$t('CHAT_WIDGET_SETTINGS.BRANDING')"
              :subheading="$t('CHAT_WIDGET_SETTINGS.BRANDING_SUBHEADING')"
            >
              <div class="flex flex-row align-start">
                <woot-avatar-uploader
                  :label="
                    $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')
                  "
                  :src="avatarUrl"
                  class="pb-4 w-[50%]"
                  delete-avatar
                  @change="handleImageUpload"
                  @onAvatarDelete="handleAvatarDelete"
                />

                <label v-if="isAWebWidgetInbox" class="w-[50%] pb-4">
                  {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
                  <woot-color-picker v-model="inbox.widget_color" />
                </label>

                <label v-if="isAWebWidgetInbox" class="w-[50%] pb-4">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_GRADIENT_COLOR.LABEL'
                    )
                  }}
                  <woot-color-picker v-model="inbox.widget_gradient_color" />
                </label>
              </div>

              <label class="w-full pb-4">
                {{
                  $t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION_LABEL'
                  )
                }}
                <select v-model="widgetBubblePosition">
                  <option
                    v-for="position in widgetBubblePositions"
                    :key="position.id"
                    :value="position.id"
                    :checked="widgetBubblePosition === position.id"
                  >
                    {{ position.title }}
                  </option>
                </select>
              </label>

              <label class="w-full pb-4">
                {{
                  $t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE_LABEL'
                  )
                }}
                <select v-model="widgetBubbleType">
                  <option
                    v-for="bubbleType in widgetBubbleTypes"
                    :key="bubbleType.id"
                    :value="bubbleType.id"
                  >
                    {{ bubbleType.title }}
                  </option>
                </select>
              </label>

              <woot-input
                v-model.trim="widgetBubbleLauncherTitle"
                :label="
                  $t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.LABEL'
                  )
                "
                :placeholder="
                  $t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.PLACE_HOLDER'
                  )
                "
              />

              <div>
                <woot-button color-scheme="primary" @click="updateInbox">
                  {{
                    $t(
                      'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.SAVE_BUTTON_TEXT'
                    )
                  }}
                </woot-button>
              </div>
            </ExpandableBox>
            <ExpandableBox
              class="mb-3"
              :heading="$t('CHAT_WIDGET_SETTINGS.FEATURES')"
              :subheading="$t('CHAT_WIDGET_SETTINGS.FEATURES_SUBHEADING')"
            >
              <label class="w-full pb-4">
                {{
                  $t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL'
                  )
                }}
                <select v-model="greetingEnabled">
                  <option :value="true">
                    {{
                      $t(
                        'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
                      )
                    }}
                  </option>
                  <option :value="false">
                    {{
                      $t(
                        'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
                      )
                    }}
                  </option>
                </select>
                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
                    )
                  }}
                </p>
              </label>

              <div v-if="greetingEnabled" class="w-full pb-4">
                <greetings-editor
                  v-model.trim="greetingMessage"
                  :label="
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'
                    )
                  "
                  :placeholder="
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER'
                    )
                  "
                  :richtext="!textAreaChannels"
                />
              </div>

              <label v-if="isAWebWidgetInbox" class="w-full pb-4">
                {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE') }}
                <select v-model="replyTime">
                  <option key="in_a_few_minutes" value="in_a_few_minutes">
                    {{
                      $t(
                        'INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES'
                      )
                    }}
                  </option>
                  <option key="in_a_few_hours" value="in_a_few_hours">
                    {{
                      $t(
                        'INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS'
                      )
                    }}
                  </option>
                  <option key="in_a_day" value="in_a_day">
                    {{
                      $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY')
                    }}
                  </option>
                </select>

                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{
                    $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT')
                  }}
                </p>
              </label>

              <label v-if="isAWebWidgetInbox" class="w-full pb-4">
                {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX') }}
                <select v-model="emailCollectEnabled">
                  <option :value="true">
                    {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED') }}
                  </option>
                  <option :value="false">
                    {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}
                  </option>
                </select>
                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{
                    $t(
                      'INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT'
                    )
                  }}
                </p>
              </label>

              <label class="w-full pb-4">
                {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT') }}
                <select v-model="csatSurveyEnabled">
                  <option :value="true">
                    {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}
                  </option>
                  <option :value="false">
                    {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}
                  </option>
                </select>
                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT_SUB_TEXT') }}
                </p>
              </label>

              <label v-if="isAWebWidgetInbox" class="w-full pb-4">
                {{
                  $t('INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED')
                }}
                <select v-model="allowMessagesAfterResolved">
                  <option :value="true">
                    {{
                      $t(
                        'INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.ENABLED'
                      )
                    }}
                  </option>
                  <option :value="false">
                    {{
                      $t(
                        'INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.DISABLED'
                      )
                    }}
                  </option>
                </select>
                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{
                    $t(
                      'INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED_SUB_TEXT'
                    )
                  }}
                </p>
              </label>

              <label v-if="isAWebWidgetInbox" class="w-full pb-4">
                {{
                  $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL')
                }}
                <select v-model="continuityViaEmail">
                  <option :value="true">
                    {{
                      $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.ENABLED')
                    }}
                  </option>
                  <option :value="false">
                    {{
                      $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.DISABLED')
                    }}
                  </option>
                </select>
                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{
                    $t(
                      'INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL_SUB_TEXT'
                    )
                  }}
                </p>
              </label>
              <div class="w-full pb-4">
                <label>
                  {{ $t('INBOX_MGMT.HELP_CENTER.LABEL') }}
                </label>
                <select v-model="selectedPortalSlug" class="filter__question">
                  <option value="">
                    {{ $t('INBOX_MGMT.HELP_CENTER.PLACEHOLDER') }}
                  </option>
                  <option v-for="p in portals" :key="p.slug" :value="p.slug">
                    {{ p.name }}
                  </option>
                </select>
                <p
                  class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
                >
                  {{ $t('INBOX_MGMT.HELP_CENTER.SUB_TEXT') }}
                </p>
              </div>

              <label v-if="isAWebWidgetInbox">
                {{ $t('INBOX_MGMT.FEATURES.LABEL') }}
              </label>
              <div v-if="isAWebWidgetInbox" class="pt-2 pb-4">
                <input
                  v-model="selectedFeatureFlags"
                  type="checkbox"
                  value="attachments"
                  @input="handleFeatureFlag"
                />
                <label for="attachments">
                  {{ $t('INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER') }}
                </label>
              </div>
              <div v-if="isAWebWidgetInbox" class="pb-4">
                <input
                  v-model="selectedFeatureFlags"
                  type="checkbox"
                  value="emoji_picker"
                  @input="handleFeatureFlag"
                />
                <label for="emoji_picker">
                  {{ $t('INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER') }}
                </label>
              </div>
              <div v-if="isAWebWidgetInbox" class="pb-4">
                <input
                  v-model="selectedFeatureFlags"
                  type="checkbox"
                  value="end_conversation"
                  @input="handleFeatureFlag"
                />
                <label for="end_conversation">
                  {{ $t('INBOX_MGMT.FEATURES.ALLOW_END_CONVERSATION') }}
                </label>
              </div>
              <div v-if="isAWebWidgetInbox" class="pb-4">
                <input
                  v-model="selectedFeatureFlags"
                  type="checkbox"
                  value="use_inbox_avatar_for_bot"
                  @input="handleFeatureFlag"
                />
                <label for="emoji_picker">
                  {{ $t('INBOX_MGMT.FEATURES.USE_INBOX_AVATAR_FOR_BOT') }}
                </label>
              </div>

              <div>
                <woot-button color-scheme="primary" @click="updateInbox">
                  {{
                    $t(
                      'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.SAVE_BUTTON_TEXT'
                    )
                  }}
                </woot-button>
              </div>
            </ExpandableBox>
            <ExpandableBox
              class="mb-3"
              :heading="$t('CHAT_WIDGET_SETTINGS.PRECHAT_FORM')"
              :subheading="$t('CHAT_WIDGET_SETTINGS.PRECHAT_FORM_SUBHEADING')"
            >
              <pre-chat-form-settings :inbox="inbox" />
            </ExpandableBox>
          </template>

          <ExpandableBox
            class="mb-3"
            :heading="$t('CHAT_WIDGET_SETTINGS.COLLABORATORS')"
            :subheading="$t('CHAT_WIDGET_SETTINGS.COLLABORATORS_SUBHEADING')"
          >
            <collaborators-page :inbox="inbox" />
          </ExpandableBox>

          <ExpandableBox
            v-if="isAWebWidgetInbox || isAnEmailChannel || isAnGmailChannel"
            class="mb-3"
            :heading="$t('CHAT_WIDGET_SETTINGS.BUSINESS_HOURS')"
            :subheading="$t('CHAT_WIDGET_SETTINGS.BUSINESS_HOURS_SUBHEADING')"
          >
            <weekly-availability :inbox="inbox" />
          </ExpandableBox>

          <template v-if="isAMetaCommentInbox">
            <ExpandableBox
              class="mb-3"
              :heading="$t('CHAT_WIDGET_SETTINGS.CHANNELS')"
              :subheading="$t('CHAT_WIDGET_SETTINGS.CHANNELS_SUBHEADING')"
            >
              <form class="pb-4" @submit.prevent="updateMetaInbox">
                <div class="pt-2">
                  <input
                    v-model="syncFacebookComments"
                    type="checkbox"
                    name="sync_facebook_comments"
                  />
                  <label for="sync_facebook_comments">
                    {{ $t('INBOX_MGMT.FEATURES.IMPORT_FACEBOOK_COMMENTS') }}
                  </label>
                </div>

                <div class="pt-2">
                  <input
                    v-model="syncInstagramComments"
                    type="checkbox"
                    name="sync_instagram_comments"
                  />
                  <label for="sync_instagram_comments">
                    {{ $t('INBOX_MGMT.FEATURES.IMPORT_INSTAGRAM_COMMENTS') }}
                  </label>
                </div>
                <woot-submit-button
                  button-text="Update"
                  :loading="uiFlags.isUpdatingSMTP"
                  :disabled="
                    ($v.$invalid && isSMTPEnabled) || uiFlags.isUpdatingSMTP
                  "
                />
              </form>
            </ExpandableBox>
            <div
              v-if="$featureFlags['autohide-negative-comments']"
              class="action-block"
            >
              <div class="action-block__head">
                <div class="action-block__head-item">
                  <Icon
                    size="16"
                    view-box="0 0 12 12"
                    icon="memory"
                    type="outline"
                    class="action-block__icon"
                    :icons="iconLib"
                  />
                </div>
                <div class="action-block__head-item">
                  <div class="mb-1 text-sm text-primary dark:text-primary-dark">
                    {{ $t('INBOX_MGMT.FEATURES.AI_HIDE_NEGATIVE_COMMENTS') }}
                  </div>
                  <div
                    class="flex-1 text-secondary text-xs mb-0 dark:text-secondary-dark"
                  >
                    {{
                      $t('INBOX_MGMT.FEATURES.AI_HIDE_NEGATIVE_COMMENTS_DESC')
                    }}
                  </div>
                </div>
              </div>
              <div class="action-block__body">
                <div class="action-block__body-item">
                  <toggle
                    :value="automaticallyHideNegativeComments"
                    size="medium"
                    @input="value => handleNegativeComment(value)"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-if="isAWebWidgetInbox">
            <quick-links class="mb-3" :inbox-id="Number(currentInboxId)" />
          </template>

          <template
            v-if="isAnEmailChannel || isAnGmailChannel || isAWebWidgetInbox"
          >
            <ExpandableBox
              :heading="
                isAnGmailChannel
                  ? $t('INBOX_MGMT.TABS.CSAT')
                  : $t('INBOX_MGMT.TABS.CONFIGURATION')
              "
              :subheading="$t('INBOX_MGMT.CSAT_SUBHEADING')"
              class="mb-3"
            >
              <configuration-page :inbox="inbox" />
            </ExpandableBox>
          </template>

          <ExpandableBox
            :heading="$t('INBOX_MGMT.STORE')"
            :subheading="$t('INBOX_MGMT.STORE_SUBHEADING')"
          >
            <multiselect
              v-model="shop"
              class="w-full pb-4"
              track-by="id"
              label="name"
              :placeholder="$t('INBOX_MGMT.STORE_PLACEHOLDER')"
              selected-label
              :select-label="$t('INBOX_MGMT.STORE')"
              :options="shopOptions"
              :searchable="false"
              :allow-empty="true"
            />

            <woot-submit-button
              button-text="Save"
              :loading="uiFlags.isUpdating"
              :disabled="uiFlags.isUpdating"
              @click="updateInbox"
            />
          </ExpandableBox>
        </div>
      </div>

      <div
        v-if="isAWebWidgetInbox"
        class="col-span-10 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4 bg-neutral-low"
      >
        <widget-builder :inbox="inbox" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { shouldBeUrl } from 'shared/helpers/Validators';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import FacebookReauthorize from './facebook/Reauthorize.vue';
import GmailReauthorize from './gmail/Reauthorize.vue';
import AircallReauthorize from './aircall/Reauthorize.vue';
import PreChatFormSettings from './PreChatForm/Settings.vue';
import WeeklyAvailability from './components/WeeklyAvailability.vue';
import CollaboratorsPage from './settingsPage/CollaboratorsPage.vue';
import WidgetBuilder from './WidgetBuilder.vue';
import SenderNameExamplePreview from './components/SenderNameExamplePreview.vue';
import QuickLinks from './QuickLinks.vue';
import ExpandableBox from './components/ExpandableBox.vue';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';
import ConfigurationPage from './settingsPage/ConfigurationPage.vue';
import GreetingsEditor from '../../../../../shared/components/GreetingsEditor.vue';
import Toggle from 'dashboard/components/ui/Switch.vue';
import icons from 'shared/components/FluentIcon/icons.json';
import Icon from 'shared/components/FluentIcon/Icon.vue';

export default {
  components: {
    ConfigurationPage,
    FacebookReauthorize,
    GmailReauthorize,
    AircallReauthorize,
    CollaboratorsPage,
    PreChatFormSettings,
    WeeklyAvailability,
    WidgetBuilder,
    SenderNameExamplePreview,
    QuickLinks,
    ExpandableBox,
    GreetingsEditor,
    Toggle,
    Icon,
  },
  mixins: [alertMixin, configMixin, inboxMixin],
  data() {
    return {
      avatarFile: null,
      avatarUrl: '',
      syncFacebookComments: false,
      syncInstagramComments: false,
      automaticallyHideNegativeComments: false,
      greetingEnabled: true,
      greetingMessage: '',
      emailCollectEnabled: false,
      csatSurveyEnabled: false,
      senderNameType: 'friendly',
      businessName: '',
      locktoSingleConversation: false,
      allowMessagesAfterResolved: true,
      continuityViaEmail: true,
      selectedInboxName: '',
      channelWebsiteUrl: '',
      webhookUrl: '',
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      selectedFeatureFlags: [],
      replyTime: '',
      selectedPortalSlug: '',
      showBusinessNameInput: false,
      widgetBubblePosition: 'right',
      widgetBubbleType: 'standard',
      widgetBubbleLauncherTitle: 'Chat with us',
      shop: null,
      widgetBubblePositions: [
        {
          id: 'left',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION.LEFT'
          ),
          checked: false,
        },
        {
          id: 'right',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION.RIGHT'
          ),
          checked: true,
        },
      ],
      widgetBubbleTypes: [
        {
          id: 'standard',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE.STANDARD'
          ),
          checked: true,
        },
        {
          id: 'expanded_bubble',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE.EXPANDED_BUBBLE'
          ),
          checked: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      uiFlags: 'inboxes/getUIFlags',
      portals: 'portals/allPortals',
      shops: 'shops/getShops',
    }),
    storageKey() {
      return `${LOCAL_STORAGE_KEYS.WIDGET_BUILDER}${this.currentInboxId}`;
    },
    whatsAppAPIProviderName() {
      if (this.isAWhatsAppCloudChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD');
      }
      if (this.is360DialogWhatsAppChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG');
      }
      if (this.isATwilioWhatsAppChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO');
      }
      return '';
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxName() {
      if (this.isATwilioSMSChannel || this.isATwilioWhatsAppChannel) {
        return `${this.inbox.name} (${
          this.inbox.messaging_service_sid || this.inbox.phone_number
        })`;
      }
      if (this.isAWhatsAppChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      if (this.isAnEmailChannel) {
        return `${this.inbox.name} (${this.inbox.email})`;
      }
      return this.inbox.name;
    },
    canLocktoSingleConversation() {
      return this.isASmsInbox || this.isAWhatsAppChannel;
    },
    inboxNameLabel() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxNamePlaceHolder() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
    textAreaChannels() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return true;
      return false;
    },
    facebookUnauthorized() {
      return this.isAFacebookInbox && this.inbox.reauthorization_required;
    },
    iconLib() {
      return icons;
    },
    gmailUnauthorized() {
      return this.isAnGmailChannel && this.inbox.reauthorization_required;
    },

    aircallUnauthorized(){
      return this.isAircallInbox && this.inbox.reauthorization_required;
    },
    shopOptions() {
      return this.shops.map(shop => {
        return { id: shop.id, name: shop.name };
      });
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
  },
  mounted() {
    this.fetchInboxSettings();
    this.fetchPortals();
    this.restoreWidgetFieldsFromLocalStorage();
  },
  methods: {
    fetchPortals() {
      this.$store.dispatch('portals/index');
    },
    handleFeatureFlag(e) {
      this.selectedFeatureFlags = this.toggleInput(
        this.selectedFeatureFlags,
        e.target.value
      );
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    fetchInboxSettings() {
      this.selectedAgents = [];
      this.$store.dispatch('agents/get');
      this.$store.dispatch('teams/get');
      this.$store.dispatch('labels/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.avatarUrl = this.inbox.avatar_url;
        this.syncFacebookComments =
          this.inbox.sync_facebook_comments_enabled || false;
        this.syncInstagramComments =
          this.inbox.sync_instagram_comments_enabled || false;
        this.automaticallyHideNegativeComments =
          this.inbox.automatically_hide_negative_comments || false;
        this.selectedInboxName = this.inbox.name;
        this.webhookUrl = this.inbox.webhook_url;
        this.greetingEnabled = this.inbox.greeting_enabled || false;
        this.greetingMessage = this.inbox.greeting_message || '';
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.csatSurveyEnabled = this.inbox.csat_survey_enabled;
        this.senderNameType = this.inbox.sender_name_type;
        this.businessName = this.inbox.business_name;
        this.allowMessagesAfterResolved =
          this.inbox.allow_messages_after_resolved;
        this.continuityViaEmail = this.inbox.continuity_via_email;
        this.channelWebsiteUrl = this.inbox.website_url;
        this.channelWelcomeTitle = this.inbox.welcome_title;
        this.channelWelcomeTagline = this.inbox.welcome_tagline;
        this.selectedFeatureFlags = this.inbox.selected_feature_flags || [];
        this.replyTime = this.inbox.reply_time;
        this.locktoSingleConversation = this.inbox.lock_to_single_conversation;
        this.selectedPortalSlug = this.inbox.help_center
          ? this.inbox.help_center.slug
          : '';
        this.shop = { id: this.inbox.shop_id, name: this.inbox.shop_name };
      });
    },
    async updateMetaInbox(negativeCommentStatus) {
      try {
        const payload = {
          id: this.currentInboxId,
          channel: {
            sync_facebook_comments_enabled: this.syncFacebookComments,
            sync_instagram_comments_enabled: this.syncInstagramComments,
            automatically_hide_negative_comments: negativeCommentStatus,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
    },

    async updateInbox() {
      // the following 3 fields are not persisted to database and are instead saved in LocalStorage
      // but they do get sent to the backend on save!
      const bubbleSettings = {
        position: this.widgetBubblePosition,
        launcherTitle: this.widgetBubbleLauncherTitle,
        type: this.widgetBubbleType,
      };

      LocalStorage.set(this.storageKey, bubbleSettings);

      try {
        const payload = {
          id: this.currentInboxId,
          name: this.selectedInboxName,
          enable_email_collect: this.emailCollectEnabled,
          csat_survey_enabled: this.csatSurveyEnabled,
          allow_messages_after_resolved: this.allowMessagesAfterResolved,
          greeting_enabled: this.greetingEnabled,
          greeting_message: this.greetingMessage || '',
          portal_id: this.selectedPortalSlug
            ? this.portals.find(
                portal => portal.slug === this.selectedPortalSlug
              ).id
            : null,
          lock_to_single_conversation: this.locktoSingleConversation,
          sender_name_type: this.senderNameType,
          business_name: this.businessName || null,
          channel: {
            widget_color: this.inbox.widget_color,
            widget_gradient_color: this.inbox.widget_gradient_color,
            website_url: this.channelWebsiteUrl,
            webhook_url: this.webhookUrl,
            welcome_title: this.channelWelcomeTitle || '',
            welcome_tagline: this.channelWelcomeTagline || '',
            selectedFeatureFlags: this.selectedFeatureFlags,
            reply_time: this.replyTime || 'in_a_few_minutes',
            continuity_via_email: this.continuityViaEmail,
            bubble_settings: {
              position: this.widgetBubblePosition,
              launcher_title: this.widgetBubbleLauncherTitle,
              type: this.widgetBubbleType,
            },
            shop_id: this.shop?.id,
          },
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch(
          'inboxes/deleteInboxAvatar',
          this.currentInboxId
        );
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
    toggleSenderNameType(key) {
      this.senderNameType = key;
    },
    onClickShowBusinessNameInput() {
      this.showBusinessNameInput = !this.showBusinessNameInput;
      if (this.showBusinessNameInput) {
        this.$nextTick(() => {
          this.$refs.businessNameInput.focus();
        });
      }
    },
    restoreWidgetFieldsFromLocalStorage() {
      const savedInformation = LocalStorage.get(this.storageKey);

      if (savedInformation) {
        this.widgetBubblePosition = savedInformation.position;
        this.widgetBubbleType = savedInformation.type;
        this.widgetBubbleLauncherTitle =
          savedInformation.launcherTitle || 'Chat with us';
      }
    },
    handleNegativeComment(negativeCommentStatus) {
      this.automaticallyHideNegativeComments =
        !this.automaticallyHideNegativeComments;
      this.updateMetaInbox(negativeCommentStatus);
    },
  },
  validations: {
    webhookUrl: {
      shouldBeUrl,
    },
    selectedInboxName: {},
  },
};
</script>

<style lang="scss" scoped>
.action-block {
  @apply flex items-center rounded-md px-4 py-2 bg-neutral dark:bg-neutral-dark border border-neutral-medium dark:border-neutral-dark-medium mb-3;
  &__head {
    @apply w-[85%] flex items-center;
  }

  &__head-item {
    &:first-child {
      @apply mr-0.5;
    }
  }

  &__icon {
    @apply w-4 text-[#5647FF] dark:text-[#9389fe] relative top-[2px] mr-2;
  }

  &__body {
    @apply flex items-center w-[15%] justify-end text-sm gap-4;
  }

  &__body-item {
    @apply flex items-center justify-end gap-2 md:w-[100%] 2xl:w-[calc(100%-180px)];
  }
}
</style>
