import posthog from 'posthog-js';
import SettingsContent from '../Wrapper';
import Index from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/workflows'),
      component: SettingsContent,
      props: {
        headerTitle: 'WORKFLOW_MGMT.HEADER',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'workflows_wrapper',
          roles: ['administrator'],
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'workflows_list',
          roles: ['administrator'],
          component: Index,
          beforeEnter: (to, from, next) => {
            // get account id from params
            const accountId = to.params.accountId;

            // if featureflag disabled redirect to homepage (dashboard)
            if (posthog.isFeatureEnabled('show-flows-ui')) {
              next();
            } else {
              next({ name: 'home', params: { accountId } });
            }
          },
        },
      ],
    },
  ],
};
