<template>
  <div
    class="flex items-center justify-between py-2 px-0 mb-1/4 border-y border-neutral-medium dark:border-neutral-dark-medium mt-2"
  >
    <div class="flex items-center w-[60%]">
      <thumbnail
        size="32px"
        :src="thumbnailSrc"
        :username="headerTitle"
        variant="square"
      />
      <div class="flex items-start flex-col ml-2 rtl:ml-0 rtl:mr-2">
        <h4
          class="text-sm max-w-[103px] mb-0 overflow-hidden whitespace-nowrap text-ellipsis text-primary dark:text-primary-dark pr-2"
        >
          {{ headerTitle }}
        </h4>
        <span class="text-secondary dark:text-secondary-dark text-xs">
          {{ subTitle }}
        </span>
      </div>
    </div>
    <div class="flex items-center justify-end w-[40%]">
      <woot-button
        v-tooltip.top="`Visit portal`"
        class="mr-1"
        variant="hollow"
        color-scheme="secondary"
        size="tiny"
        icon="arrow-up-right"
        @click="popoutHelpCenter"
      />
      <woot-button
        v-tooltip.top="`Switch portal`"
        variant="hollow"
        size="tiny"
        color-scheme="secondary"
        icon="arrow-swap"
        @click="openPortalPopover"
      />
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
export default {
  components: {
    Thumbnail,
  },
  props: {
    thumbnailSrc: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    portalLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    popoutHelpCenter() {
      window.open(this.portalLink, '_blank');
    },
    openPortalPopover() {
      this.$emit('open-popover');
    },
  },
};
</script>
