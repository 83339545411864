<template>
  <div
    class="banner flex items-center h-12 gap-4 text-white dark:text-white text-xs py-3 px-4 justify-center"
    :class="bannerClasses"
  >
    <span class="banner-message">
      {{ bannerMessage }}
      <a
        v-if="hrefLink"
        :href="hrefLink"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        {{ hrefLinkText }}
      </a>
    </span>
    <div class="actions">
      <woot-button
        v-if="hasActionButton"
        size="tiny"
        icon="arrow-right"
        :variant="actionButtonVariant"
        :color-scheme="colorScheme"
        class-names="banner-action__button"
        @click="onClick"
      >
        {{ actionButtonLabel }}
      </woot-button>
      <woot-button
        v-if="hasCloseButton"
        size="tiny"
        :color-scheme="colorScheme"
        icon="dismiss-circle"
        class-names="banner-action__button"
        @click="onClickClose"
      >
        {{ $t('GENERAL_SETTINGS.DISMISS') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerMessage: {
      type: String,
      default: '',
    },
    hrefLink: {
      type: String,
      default: '',
    },
    hrefLinkText: {
      type: String,
      default: '',
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
    actionButtonVariant: {
      type: String,
      default: '',
    },
    actionButtonLabel: {
      type: String,
      default: '',
    },
    colorScheme: {
      type: String,
      default: '',
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bannerClasses() {
      const classList = [this.colorScheme];

      if (this.hasActionButton || this.hasCloseButton) {
        classList.push('has-button');
      }
      return classList;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onClickClose(e) {
      this.$emit('close', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  &.primary {
    @apply bg-accent bg-opacity-[0.2] text-accent dark:text-accent-hover;
  }

  &.secondary {
    @apply bg-slate-200 dark:bg-slate-300 text-slate-800 dark:text-slate-800;
    a {
      @apply text-slate-800 dark:text-slate-800;
    }
  }

  &.alert {
    @apply bg-error bg-opacity-[0.2] text-error-medium dark:text-error-dark-medium;
  }

  &.warning {
    @apply bg-warning bg-opacity-[0.2] text-warning-high dark:text-warning-dark-high;

    a {
      @apply text-warning-high dark:text-warning-dark-high;
    }
  }

  a {
    @apply ml-1 underline text-white dark:text-white text-xs;
  }

  .banner-action__button {
    ::v-deep .button__content {
      @apply whitespace-nowrap;
    }
  }

  .banner-message {
    @apply flex items-center;
  }

  .actions {
    @apply flex gap-1 right-3;
  }
}
</style>
