/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  exportConversations(period) {
    return axios.post(`${this.url}/export_conversations`, { period });
  }

  async getCacheKeys() {
    const response = await axios.get(
      `/api/v1/accounts/${this.accountIdFromRoute}/cache_keys`
    );
    return response.data.cache_keys;
  }

  createAircallAuthURL(params) {
    return axios.post(`${this.url}create_aircall_auth_url`, params);
  }

  reauthorizeAircall(accountId, params) {
    return axios.post(`/api/v1/accounts/${accountId}/callbacks/reauthorize_aircall`, params);
  }

  createKlaviyoAuthURL(accountId) {
    return axios.post(`${this.url}accounts/${accountId}/create_klaviyo_auth_url`);
  }
}

export default new AccountAPI();
