<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      size="small"
      @click="openAddPopup"
    >
      {{ $t('SHOP_MGMT.HEADER_BTN_TXT') }}
    </woot-button>
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <p
          v-if="!uiFlags.isFetching && !records.length"
          class="flex h-full items-center flex-col justify-center"
        >
          {{ $t('SHOP_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('SHOP_MGMT.LOADING')"
        />
        <table v-if="!uiFlags.isFetching && records.length" class="woot-table">
          <thead>
            <th
              v-for="thHeader in $t('SHOP_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(shop, index) in records" :key="shop.shopify_domain">
              <td>
                <span class="mr-2">{{ shop.shopify_domain }}</span>
                <span
                  v-if="shop.need_reauth"
                  class="inline-flex button smooth button--only-icon tiny alert grey-btn"
                >
                  <fluent-icon
                    v-tooltip.top-end="$t('SHOP_MGMT.REAUTHORIZE')"
                    class="text-xxs"
                    icon="warning"
                    size="12"
                  />
                </span>
              </td>

              <td class="button-wrapper">
                <a
                  v-if="shop.need_reauth"
                  target="_blank"
                  class="mr-2 items-center flex"
                  @click="openReauthLink(shop)"
                >
                  Re-auth
                </a>

                <woot-button
                  v-if="isAdmin"
                  v-tooltip.top="$t('SHOP_MGMT.FORM.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[shop.id]"
                  @click="openDeletePopup(shop, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-[34%]">
        <span v-dompurify-html="$t('SHOP_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-shop @close="hideAddPopup" />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('SHOP_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('SHOP_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :info-message-value="'Deleting a shop will also delete all conversation history and the inbox associated with that shop.'"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AddShop from './AddShop';
import adminMixin from '../../../../mixins/isAdmin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    AddShop,
  },
  mixins: [adminMixin, alertMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'shops/getShops',
      uiFlags: 'shops/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return this.$t('LABEL_MGMT.DELETE.CONFIRM.YES');
    },
    deleteRejectText() {
      return this.$t('LABEL_MGMT.DELETE.CONFIRM.NO');
    },
    deleteMessage() {
      return ` ${this.selectedResponse.shopify_domain}?`;
    },
  },
  mounted() {
    this.$store.dispatch('shops/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    openReauthLink(shop) {
      window.location = shop.reauth_url;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteLabel(this.selectedResponse.id);
    },
    deleteLabel(id) {
      this.$store
        .dispatch('shops/delete', id)
        .then(() => {
          this.showAlert(this.$t('SHOP_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(() => {
          this.showAlert(this.$t('SHOP_MGMT.DELETE.API.ERROR_MESSAGE'));
        })
        .finally(() => {
          this.loading[this.selectedResponse.id] = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label-title {
  span {
    @apply w-60 inline-block;
  }
}
</style>
