<template>
  <div
    v-tooltip.right="tooltipOptions"
    class="rounded-md border-1 p-2 cursor cursor-pointer mt-2"
    :class="
      isSidebarExpanded
        ? 'bg-neutral dark:bg-neutral-dark sticky bottom-0 left-0 border border-primary-dark dark:border-[#474D52]'
        : 'hover:bg-neutral-medium dark:hover:bg-[#222527]'
    "
    @click="showModal"
  >
    <template v-if="isSidebarExpanded">
      <div class="font-medium text-primary dark:text-primary-dark">
        {{ $t('SIDEBAR.ONBOARDING.GETTING_STARTED') }}
      </div>
      <div class="text-secondary mb-2 text-xs">
        {{
          $t('SIDEBAR.ONBOARDING.PROGRESS_MESSAGE', {
            stepsComplete,
            totalSteps,
          })
        }}
      </div>

      <div
        class="bg-secondary-medium dark:bg-primary-dark h-2 rounded overflow-hidden"
      >
        <div
          class="bg-accent dark:bg-accent-dark h-full"
          :style="{ width: progressPercentage + '%' }"
        />
      </div>

      <a
        href="#dismiss"
        class="text-secondary absolute top-1 right-1"
        @click.stop.prevent="dismiss"
      >
        <Icon icon="dismiss" type="outline" size="16" :icons="iconLib" />
      </a>
    </template>
    <template v-else>
      <div
        class="flex items-center justify-center text-secondary text-xs"
        @click="showModal"
      >
        {{ stepsComplete }}/{{ totalSteps }}
      </div>
    </template>
  </div>
</template>

<script>
import onboardingMixin from 'dashboard/mixins/onboardingMixin';
import accountMixin from 'dashboard/mixins/account';
import icons from 'shared/components/FluentIcon/dashboard-icons.json';
import Icon from 'shared/components/FluentIcon/Icon';

export default {
  components: {
    Icon,
  },
  mixins: [accountMixin, onboardingMixin],
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progressPercentage() {
      return (this.stepsComplete / this.totalSteps) * 100;
    },
    iconLib() {
      return icons;
    },
    tooltipOptions() {
      if (this.isSidebarExpanded) {
        return { content: '', classes: '' };
      }
      return {
        content: this.$t('SIDEBAR.ONBOARDING.GETTING_STARTED'),
        classes: 'new-sidebar-tooltip',
      };
    },
  },
  methods: {
    showModal() {
      this.$root.$emit('onboarding-modal.show');
    },
    dismiss() {
      this.$store.dispatch('accounts/dismissOnboarding');
    },
  },
};
</script>

<style lang="scss" scoped></style>
