<template>
  <div
    v-tooltip.right="tooltipOptions"
    class="agent-detail"
    :class="{ 'justify-center': !isSidebarExpanded }"
    @click="handleClick"
  >
    <button
      class="agent-detail__prefix"
      :class="{ 'mr-2 rtl:mr-0 rtl:ml-2': isSidebarExpanded }"
    >
      <thumbnail
        :src="currentUser.avatar_url"
        :username="currentUser.name"
        :status="statusOfAgent"
        should-show-status-always
        size="32px"
      />
    </button>
    <template v-if="isSidebarExpanded">
      <div class="agent-detail__text">
        <div
          class="text-secondary dark:text-secondary-dark text-xs leading-none"
        >
          Agent
        </div>
        <div class="text-sm leading-none mt-1">{{ currentUser.name }}</div>
      </div>
      <div class="agent-detail__suffix">
        <fluent-icon size="16" icon="kebab" type="outline" />
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
    tooltipOptions() {
      if (this.isSidebarExpanded) {
        return { content: '', classes: '' };
      }
      return {
        content: 'Profile setting',
        classes: 'new-sidebar-tooltip',
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
  },
};
</script>

<style scoped lang="scss">
.agent-detail {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  p-2
  m-0
  min-h-[40px]
  text-sm
  text-primary
  dark:text-primary-dark
  border
  border-transparent
  cursor-pointer
  mt-2;

  &__prefix {
    @apply flex items-center justify-center w-[32px] h-[32px] relative;
  }
  &__text {
    @apply leading-none w-[80%];
  }
  &__suffix {
    @apply flex items-center justify-center w-[32px] h-[24px] relative ml-2 rtl:mr-0 rtl:ml-2 rounded-[4px];
  }

  &:hover {
    .agent-detail__suffix {
      @apply bg-neutral-medium dark:bg-[#222527];
    }
  }
}

::v-deep {
  .user-thumbnail-box .user-thumbnail {
    @apply cursor-pointer;
  }
}
</style>
