var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',[_vm._v("\n    "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.URL_BASE'))+"\n\n    "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.urlBase),expression:"urlBase"}],class:{
        'mb-0': !_vm.$v.urlBase.url,
        'border-error dark:border-error-dark': !_vm.$v.urlBase.url,
      },attrs:{"type":"text","placeholder":_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.URL_BASE_PLACEHOLDER')},domProps:{"value":(_vm.urlBase)},on:{"input":function($event){if($event.target.composing)return;_vm.urlBase=$event.target.value}}}),_vm._v(" "),(!_vm.$v.urlBase.url)?_c('div',{staticClass:"text-sm text-error dark:text-error-dark mt-0.5 mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.INVALID_URL'))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('label',[_vm._v("\n    "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.USERNAME'))+"\n\n    "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],class:{
        'mb-0': !_vm.$v.username.email,
        'border-error dark:border-error-dark': !_vm.$v.username.email,
      },attrs:{"type":"email","placeholder":_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.USERNAME_PLACEHOLDER')},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),_vm._v(" "),(!_vm.$v.username.email)?_c('div',{staticClass:"text-sm text-error dark:text-error-dark mt-0.5 mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.INVALID_EMAIL'))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('label',[_vm._v("\n    "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.API_KEY'))+"\n\n    "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.apiKey),expression:"apiKey"}],attrs:{"type":"password"},domProps:{"value":(_vm.apiKey)},on:{"input":function($event){if($event.target.composing)return;_vm.apiKey=$event.target.value}}})]),_vm._v(" "),(_vm.status && _vm.status.stage)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"mt-3 border border-solid border-slate-200 dark:border-slate-600 rounded-md bg-white dark:bg-slate-900 p-3"},[_c('div',{staticClass:"flex items-start"},[_c('img',{attrs:{"src":require("dashboard/assets/images/cloud-download.svg")}}),_vm._v(" "),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"dark:text-slate-100 mb-0 text-sm"},[_vm._v("\n            "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.STATUS_HEADING'))+"\n          ")]),_vm._v(" "),(_vm.status.stage == 'finished')?_c('div',[_c('div',{staticClass:"text-green-500 dark:text-green-500 text-sm"},[_vm._v("\n              "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.FINISHED'))+"\n            ")])]):(_vm.status.stage === 'error')?_c('div',[_c('div',{staticClass:"text-red-500 dark:text-red-500 text-sm"},[_vm._v("\n              "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.ERROR').split(
                  _vm.supportEmail
                )[0])+"\n              "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Copy' }),expression:"{ content: 'Copy' }"}],staticClass:"cursor-pointer hover:text-error-dark-medium dark:hover:text-error-dark-medium",on:{"click":function($event){return _vm.handleCopy(_vm.supportEmail)}}},[_vm._v("\n                "+_vm._s(_vm.supportEmail)+"\n              ")]),_vm._v("\n              "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.ERROR').split(
                  _vm.supportEmail
                )[1])+"\n            ")])]):(_vm.status.stage === 'started')?_c('div',[_c('div',{staticClass:"text-slate-400 dark:text-slate-300 text-sm"},[_vm._v("\n              "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.STARTED'))+"\n            ")])]):_c('div',{staticClass:"text-slate-400 dark:text-slate-300 text-sm"},[_vm._v("\n            "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.STATUS_MESSAGE', {
                stage: _vm.currentStageName,
                n: _vm.currentStageNumRecords,
              }))+"\n          ")])])]),_vm._v(" "),(_vm.showProgressBar)?_c('div',{staticClass:"mt-3 bg-slate-300 h-2 rounded overflow-hidden"},[_c('div',{staticClass:"bg-woot-300 h-full",style:({ width: _vm.progressPercentage + '%' })})]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex content-center"},[_c('woot-button',{attrs:{"disabled":_vm.importInProgress},on:{"click":function($event){$event.preventDefault();return _vm.startImport()}}},[_vm._v("\n      "+_vm._s(_vm.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.IMPORT'))+"\n    ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }