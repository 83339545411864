<template>
  <div v-if="isInProgress" class="card-progress-bar">
    <div class="card-progress-bar__body">
      <div class="card-progress-bar__prefix">
        <icon
          class="card-progress-bar__icon"
          :size="iconSize"
          :view-box="iconViewBox"
          :icon="iconName"
          type="outline"
          :icons="iconLib"
        />
      </div>
      <div class="card-progress-bar__suffix">
        <div class="card-progress-bar__title">{{ title }}</div>
        <div class="card-progress-bar__desc">{{ desc }}</div>
      </div>
    </div>
    <div class="card-progress-bar__footer">
      <div class="progress-status">
        <div class="progress-status__prefix">
          <div class="progress-status__bar">
            <div
              class="progress-status__indicator"
              :style="`width: ${(percentage / 100) * 100}%`"
            />
          </div>
        </div>
        <div class="progress-status__suffix">{{ percentage }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';

export default {
  components: {
    Icon,
  },
  props: {
    iconName: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '',
    },
    iconViewBox: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    isInProgress: {
      type: Boolean,
      default: true,
    },
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    iconLib() {
      return icons;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-progress-bar {
  @apply bg-white dark:bg-primary flex-col items-center w-full min-h-[95px] p-4 border-b border-neutral-medium dark:border-neutral-dark-medium;

  &__body {
    @apply flex items-center;
  }

  &__prefix {
    @apply flex items-center justify-center w-[36px] h-[36px] relative rounded-lg bg-accent-low dark:bg-accent-dark-low mr-2;
  }

  &__icon {
    ::v-deep path {
      @apply stroke-accent dark:stroke-accent-dark;
      stroke-width: 1;
    }
  }

  &__title {
    @apply text-sm text-primary dark:text-primary-dark font-medium;
  }

  &__desc {
    @apply text-sm text-secondary dark:text-secondary-dark;
  }
}

.progress-status {
  @apply flex items-center w-full mt-2;

  &__prefix {
    @apply w-full mr-2;
  }
  &__suffix {
    @apply text-sm text-primary dark:text-primary-dark leading-none;
  }

  &__bar {
    @apply w-full h-[8px] rounded-[8px] bg-secondary-medium dark:bg-secondary-dark-medium relative;
  }

  &__indicator {
    @apply absolute top-0 left-0 h-full bg-accent-hover dark:bg-accent-dark rounded-[8px] w-[20%] transition-all duration-100 ease-in;
  }
}
</style>
